import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import Layout from '../layouts/layout';
import AppointmentCard from './AppointmentCard';
import axiosInstance from '../../../helpers/axios';
import { Skeleton } from '@material-ui/lab';



const VisitHistory = ({ match }) => {

    const [cardData,setCardData] = useState([]);
    const [requestReponse,setRequestReponse] = useState(false);

    useEffect(()=>{
    getScheduledAppointment();
    },[])

        
    const  getScheduledAppointment = async (data)=>{
        await axiosInstance.post('/provider/booking/complete/list',data)
        .then(response => {
          setCardData(response.data.data)
          setRequestReponse(true)
        })
        .catch(err =>{
            console.log(err)
        });
      };

    return (
             <Layout>
               
                <Typography variant="h3">
                Visit History
                </Typography>

                <Box mt={3}>
                    <Grid container spacing={3}>
                        { cardData.length > 0 || requestReponse ? cardData.map((item,index)=>
                                <AppointmentCard data={item} index={index} key={index}/>
                            )
                            :
                            [1,2,3,4,6,7,8,5,4,8].map((item,index)=>
                            <Grid item  lg={4}  sm={12} xl={3} xs={12} key={index}>
                                <Skeleton variant="rect" width={'100%'} height={250} />
                            </Grid>
                            )
                            
                        }

                        { cardData.length === 0 &&  <Grid item  lg={12}  sm={12} xl={12} xs={12}><Typography style={{textAlign: "center"}} variant="h6" gutterBottom>No Appointment </Typography></Grid>}

                    </Grid>
                </Box>
            </Layout>
        )
    }
export default VisitHistory;
import React, { useState } from "react";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

const AgGridCustom = (props) => {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const { columnDefs, rowData, serverSideDatasource,headerCheckboxSelection, headerCheckboxSelectionFilteredOnly, checkboxSelection, getRowNodeId } = props;

  return (
    <div className="example-wrapper">
      <div
        id="myGrid"
        style={{
          height: '80vh',
          width: '100%',
        }}
        className="ag-theme-material"
      >
        <AgGridReact
          columnDefs={columnDefs}
          defaultColDef={{
            flex: 1,
            sortable: true,
            filter: true,
            resizable: false
          }}
          animateRows={true}
          rowData={rowData}
          rowSelection='multiple'
          getRowNodeId={getRowNodeId}
          onGridReady={onGridReady}
          serverSideDatasource={serverSideDatasource}
          {...props}
        />
      </div>
    </div>

  )
}

export default AgGridCustom;
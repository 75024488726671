import React, { useEffect, useState } from "react";
import { Box, Grid, FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  DateField,
  InputField,
  LoadingButton,
  PhoneNoField,
  AutoCompleteField,
  AutoCompleteMultipleField,
} from "../../../components/forms/FormFields";
import { useForm } from "react-hook-form";
import axiosInstance from "../../../helpers/axios";
import { store } from "react-notifications-component";
import DateFnsUtils from "@date-io/date-fns";
import logo from "../../../assets/images/logos/logo.png";
import Typography from "@material-ui/core/Typography";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { setCurrentUser } from "../../../helpers/Utils";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  marginTopZero: {
    marginTop: theme.spacing(0),
  },
}));

const AuthRegister = (props) => {
  const classes = useStyles();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [socialLoginData, setSocialLoginData] = useState({});

  const [loader, setLoader] = useState(false);
  const [defaultCity, setDefaultCity] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const [providerType, setProviderType] = useState(null);

  const provider_type = [
    { id: "individual", name: "Individual" },
    { id: "practice", name: "Practice" },
  ];

  useEffect(() => {
    let regData = location?.state;
    setSocialLoginData(regData);
    if (regData) {
      setValue("email", regData?.email);
      setValue("first_name", regData?.displayName?.split(" ")?.[0]);
      setValue("last_name", regData?.displayName?.split(" ")?.[1]);
    }
  }, [location]);

  const onSubmit = async (data) => {
    //setLoader(true);
    data.city_id = data.city_id.id;
    data.provider_type = data.provider_type.id;
    data.type = "provider";
    data.device_token = "web";
    data.device_type = "web";
    data.phone_no = data?.phone_no?.replace("+1", "").trim();
    data.social_type = socialLoginData?.social_type;
    data.state_id = data?.state_id?.id;
    if (data.speciality_ids.id !== undefined) {
      data.speciality_ids = data.speciality_ids.id.toString();
    } else {
      data.speciality_ids = data.speciality_ids.map(({ id }) => id).toString();
    }
    data.speciality_id = 1;

    data.avatar = socialLoginData?.photoURL;

    await axiosInstance
      .post("/account/reg", data)
      .then((response) => {
        const {
          token,
          user: {
            id,
            full_name,
            uuid,
            avatar: { thumb },
            type,
          },
        } = response.data.data;

        setCurrentUser({
          token: token,
          uuid: uuid,
          fullName: full_name,
          avatar: thumb,
          id: id,
        });

        store.addNotification({
          title: "Success",
          message: "Register Successfully.",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });

        setLoader(false);
        window.location.reload();
        history.push("/account");
      })
      .catch((err) => {
        setLoader(false);
        let _data = err.response?.data?.data?.errors;
        if (_data !== undefined) {
          for (const key in _data) {
            if (Object.hasOwnProperty.call(_data, key)) {
              const element = _data[key];
              if (element?.[0]) {
                setError(key, {
                  type: "manual",
                  message: element?.[0],
                });
                store.addNotification({
                  title: "Error",
                  message: element?.[0],
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 5000,
                    onScreen: true,
                  },
                });
              }
            }
          }
        }
        store.addNotification({
          title: "Error",
          message: err.response?.data?.message
            ? err.response?.data?.message
            : err.response?.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      });
  };

  useEffect(() => {
    //getRegister();
    getStates();
    getSpecialities();
  }, []);

  const getStates = async (data) => {
    await axiosInstance
      .get("/list/states", data)
      .then((response) => {
        setStates(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSpecialities = async (data) => {
    await axiosInstance
      .get("/specialities", data)
      .then((response) => {
        console.log("data", response.data.data);
        setSpecialities(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCities = async (state_id = null) => {
    console.log(state_id);
    setDefaultCity([]);
    await axiosInstance
      .get("/list/cities", {
        params: {
          state_id: state_id,
        },
      })
      .then((response) => {
        setCities(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    setError,
    watch,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const handleChangeState = (value) => {
    setValue("state_id", value?.id);
    setValue("city_id", []);
    getCities(value?.id);
  };

  const handleChangeProviderType = (value) => {
    setProviderType(value?.id);
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={9}>
        <div className={classes.paper}>
          <img alt="logo" src={logo} />
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <CardContent>
                  <Box mt={2}>
                    <Grid container spacing={3}>
                      <Grid item lg={4} sm={6} xl={4} xs={12}>
                        <InputField
                          errors={!!errors?.first_name}
                          fieldName="first_name"
                          type="text"
                          autoComplete="off"
                          label="First Name"
                          control={control}
                          valueGot={""}
                          setValue={setValue}
                          helperText={
                            errors?.first_name && errors?.first_name?.message
                          }
                          rules={{
                            required: "Please enter first name",
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} sm={6} xl={4} xs={12}>
                        <InputField
                          errors={!!errors?.last_name}
                          fieldName="last_name"
                          type="text"
                          autoComplete="off"
                          label="Last Name"
                          control={control}
                          valueGot={""}
                          setValue={setValue}
                          helperText={
                            errors?.last_name && errors?.last_name?.message
                          }
                          rules={{
                            required: "Please enter last name",
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} sm={6} xl={4} xs={12}>
                        <InputField
                          errors={!!errors?.email}
                          fieldName="email"
                          type="text"
                          autoComplete="off"
                          label="Email"
                          control={control}
                          valueGot={""}
                          setValue={setValue}
                          helperText={errors?.email && errors?.email?.message}
                          iProps={{
                            disabled: !!socialLoginData?.email,
                          }}
                          rules={{
                            required: "Please enter email",
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} sm={6} xl={4} xs={12}>
                        <InputField
                          errors={!!errors?.password}
                          fieldName="password"
                          type="password"
                          autoComplete="off"
                          label="Enter Password"
                          control={control}
                          valueGot={""}
                          setValue={setValue}
                          iProps={{
                            className: classes.marginTopZero,
                          }}
                          helperText={
                            errors?.password && errors?.password?.message
                          }
                          rules={{
                            required: "Please enter password",
                          }}
                        />
                        <Box mt={1}>
                          <PhoneNoField
                            errors={!!errors?.phone_no}
                            fieldName="phone_no"
                            autoComplete="off"
                            label="Phone No"
                            control={control}
                            valueGot={""}
                            setValue={setValue}
                            helperText={
                              errors?.phone_no && errors?.phone_no?.message
                            }
                            rules={{
                              required: "Please enter phone no",
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item lg={8} sm={12} xl={8} xs={12}>
                        <InputField
                          errors={!!errors?.address}
                          fieldName="address"
                          type="text"
                          autoComplete="off"
                          label="Address"
                          valueGot={""}
                          setValue={setValue}
                          iProps={{
                            multiline: true,
                            rows: 4,
                            className: classes.marginTopZero,
                          }}
                          control={control}
                          helperText={
                            errors?.first_name && errors?.first_name?.message
                          }
                          rules={{
                            required: "Please enter first name",
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} sm={6} xl={4} xs={12}>
                        {states && (
                          <AutoCompleteField
                            errors={!!errors?.state_id}
                            fieldName="state_id"
                            type="text"
                            autoComplete="off"
                            label="State"
                            control={control}
                            helperText={
                              errors?.state_id && errors?.state_id?.message
                            }
                            valueGot={""}
                            optionKey="id"
                            optionValue="name"
                            returnObject={true}
                            setValue={setValue}
                            options={states}
                            isLoading={states?.length > 0 ? true : false}
                            iProps={{ onChange: handleChangeState }}
                            rules={{
                              required: "Please select state",
                            }}
                          />
                        )}
                      </Grid>
                      <Grid item lg={4} sm={6} xl={4} xs={12}>
                        <AutoCompleteField
                          errors={!!errors?.city_id}
                          fieldName="city_id"
                          autoComplete="off"
                          label="City"
                          control={control}
                          helperText={
                            errors?.city_id && errors?.city_id?.message
                          }
                          rules={{
                            required: "Please select city",
                          }}
                          optionKey="id"
                          optionValue="name"
                          returnObject={true}
                          setValue={setValue}
                          placeHolder="Select City"
                          options={cities}
                          isLoading={true}
                        />
                      </Grid>
                      <Grid item lg={4} sm={6} xl={4} xs={12}>
                        <InputField
                          errors={!!errors?.zip_code}
                          fieldName="zip_code"
                          type="text"
                          autoComplete="off"
                          label="Zip Code"
                          valueGot={""}
                          setValue={setValue}
                          iProps={{
                            className: classes.marginTopZero,
                          }}
                          control={control}
                          helperText={
                            errors?.zip_code && errors?.zip_code?.message
                          }
                          rules={{
                            required: "Please enter zip code",
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} sm={6} xl={4} xs={12}>
                        <AutoCompleteField
                          errors={!!errors?.provider_type}
                          fieldName="provider_type"
                          type="text"
                          autoComplete="off"
                          label="Provider Type"
                          control={control}
                          optionKey="id"
                          optionValue="name"
                          returnObject={true}
                          helperText={
                            errors?.provider_type &&
                            errors?.provider_type?.message
                          }
                          iProps={{
                            onChange: handleChangeProviderType,
                          }}
                          setValue={setValue}
                          valueGot={""}
                          isLoading={true}
                          options={provider_type}
                          rules={{
                            required: "Please select provider type",
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} sm={6} xl={4} xs={12}>
                        {(providerType == "individual" ||
                          providerType == null) && (
                          <AutoCompleteField
                            errors={!!errors?.speciality_ids}
                            fieldName="speciality_ids"
                            type="text"
                            autoComplete="off"
                            label="* Select Speciality"
                            control={control}
                            helperText={
                              errors?.speciality_ids &&
                              errors?.speciality_ids?.message
                            }
                            onChange={handleChangeState}
                            optionKey="id"
                            isLoading={specialities?.length > 0 ? true : false}
                            optionValue="name"
                            setValue={setValue}
                            options={specialities}
                            returnObject={true}
                            rules={{
                              required: "Please select Speciality",
                            }}
                          />
                        )}
                        {providerType == "practice" && (
                          <AutoCompleteMultipleField
                            errors={!!errors?.speciality_ids}
                            fieldName="speciality_ids"
                            autoComplete="off"
                            label="* Select Specialities"
                            control={control}
                            setValue={setValue}
                            options={specialities}
                            optionKey="id"
                            isLoading={specialities?.length > 0 ? true : false}
                            optionValue="name"
                            helperText={
                              errors?.speciality_ids &&
                              errors?.speciality_ids?.message
                            }
                          />
                        )}
                      </Grid>

                      {providerType == "practice" && (
                        <Grid item lg={4} sm={6} xl={4} xs={12}>
                          <InputField
                            errors={!!errors?.business_name}
                            fieldName="business_name"
                            type="text"
                            autoComplete="off"
                            label="Business Name"
                            valueGot={""}
                            setValue={setValue}
                            iProps={{
                              className: classes.marginTopZero,
                            }}
                            control={control}
                            helperText={
                              errors?.business_name &&
                              errors?.business_name?.message
                            }
                            rules={{
                              required:
                                providerType == "practice"
                                  ? "Please enter business name"
                                  : false,
                            }}
                          />
                        </Grid>
                      )}

                      <Grid item lg={4} sm={6} xl={4} xs={12}>
                        <InputField
                          errors={!!errors?.bank_name}
                          fieldName="bank_name"
                          type="text"
                          autoComplete="off"
                          label="Bank Name"
                          valueGot={""}
                          setValue={setValue}
                          iProps={{
                            className: classes.marginTopZero,
                          }}
                          control={control}
                          helperText={
                            errors?.bank_name && errors?.bank_name?.message
                          }
                          rules={{
                            required: "Please enter bank name",
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} sm={6} xl={4} xs={12}>
                        <InputField
                          errors={!!errors?.account_number}
                          fieldName="account_number"
                          type="text"
                          autoComplete="off"
                          label="Account Number"
                          valueGot={""}
                          setValue={setValue}
                          iProps={{
                            className: classes.marginTopZero,
                          }}
                          control={control}
                          helperText={
                            errors?.account_number &&
                            errors?.account_number?.message
                          }
                          rules={{
                            required: "Please enter account number",
                            // minLength: {
                            //   value: 10,
                            //   message: "Please enter valid account number",
                            // },
                            // maxLength: {
                            //   value: 13,
                            //   message: "Please enter valid account number",
                            // },
                            // pattern: {
                            //   value: /[0-9]{10}/,
                            //   message: "Please enter valid account number.", // JS only: <p>error message</p> TS only support string
                            // },
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} sm={6} xl={4} xs={12}>
                        <InputField
                          errors={!!errors?.routing_number}
                          fieldName="routing_number"
                          type="text"
                          autoComplete="off"
                          label="Routing Number"
                          valueGot={""}
                          setValue={setValue}
                          iProps={{
                            className: classes.marginTopZero,
                          }}
                          control={control}
                          helperText={
                            errors?.routing_number &&
                            errors?.routing_number?.message
                          }
                          rules={{
                            required: "Please enter routing number",
                            // minLength: {
                            //   value: 9,
                            //   message: "Please enter valid routing number",
                            // },
                            // maxLength: {
                            //   value: 13,
                            //   message: "Please enter valid routing number",
                            // },
                            // pattern: {
                            //   value: /[0-9]{9}/,
                            //   message: "Please enter valid routing number.", // JS only: <p>error message</p> TS only support string
                            // },
                          }}
                        />
                      </Grid>

                      {providerType == "practice" && (
                        <Grid item lg={4} sm={6} xl={4} xs={12}>
                          <InputField
                            errors={!!errors?.tax_id}
                            fieldName="tax_id"
                            type="text"
                            autoComplete="off"
                            label="Tax Id"
                            valueGot={""}
                            setValue={setValue}
                            iProps={{
                              className: classes.marginTopZero,
                            }}
                            control={control}
                            helperText={
                              errors?.tax_id && errors?.tax_id?.message
                            }
                            rules={{
                              required:
                                providerType == "practice"
                                  ? "Please enter tax id"
                                  : false,
                            }}
                          />
                        </Grid>
                      )}
                      {providerType == "individual" && (
                        <Grid item lg={4} sm={6} xl={4} xs={12}>
                          <InputField
                            errors={!!errors?.ssn}
                            fieldName="ssn"
                            type="text"
                            autoComplete="off"
                            label="SSN"
                            valueGot={""}
                            setValue={setValue}
                            iProps={{
                              className: classes.marginTopZero,
                            }}
                            control={control}
                            helperText={errors?.ssn && errors?.ssn?.message}
                            rules={{
                              required: "Please enter ssn",
                            }}
                          />
                        </Grid>
                      )}
                      <Grid item lg={4} sm={6} xl={4} xs={12}>
                        <DateField
                          errors={!!errors?.dob}
                          fieldName="dob"
                          autoComplete="off"
                          label="Date of Birth"
                          iProps={{
                            format: "MM/dd/yyyy",
                            maxDate: moment().subtract(16, "years"),
                          }}
                          valueGot={moment().subtract(16, "years")}
                          setValue={setValue}
                          control={control}
                          helperText={errors?.dob && errors?.dob?.message}
                          rules={{
                            required: "Please enter date of birth",
                          }}
                        />
                      </Grid>

                      <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          loading={loader}
                          text="Register"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </form>
          </MuiPickersUtilsProvider>
        </div>
      </Grid>
    </Grid>
  );
};
export default AuthRegister;

import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  Link,
  useHistory,
} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../../assets/images/logos/logo.png";
import { useForm } from "react-hook-form";
import {
  InputField,
  LoadingButton,
} from "../../../components/forms/FormFields";
import axiosInstance from "../../../helpers/axios";
import { setCurrentUser } from "../../../helpers/Utils";
import { Box, Button } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import {
  auth,
  faceBookProvider,
  googleProvider,
} from "../../../services/firebase";
import { Search } from "@trejgun/material-ui-icons-google";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  loginWithFb: {
    backgroundColor: "rgb(255, 255, 255)",
    display: "inline-flex",
    alignItems: "center",
    color: "rgba(0, 0, 0, 0.54)",
    boxShadow:
      "rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px",
    borderRadius: "2px",
    border: "1px solid transparent",
    fontSize: "14px",
    fontFamily: "Roboto, sans-serif",
    padding: "10px 10px 10px 0px",
    fontWeight: "500",
  },
}));

function Login(props) {
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const classes = useStyles();

  const onSubmit = async (data) => {
    data.device_type = "web";
    setLoading(true);
    await axiosInstance
      .post("/login", data)
      .then((response) => {
        setLoading(false);
        const {
          token,
          user: {
            id,
            full_name,
            uuid,
            avatar: { thumb },
          },
        } = response.data.data;

        setCurrentUser({
          token: token,
          uuid: uuid,
          fullName: full_name,
          avatar: thumb,
          id: id,
        });

        window.location.reload();
        history.push("/account");
      })
      .catch((err) => {
        setLoading(false);
        setError("email", { message: err?.response?.data?.message });
      });
  };

  const signInWithGoogle = () => {
    auth
      .signInWithPopup(googleProvider)
      .then((res) => {
        var user = res.user;
        setLoading(true);
        let data = {
          email: res.user?.email
            ? res.user?.email
            : user?.providerData?.[0]?.email,
          social_type: "gmail",
          device_type: "web",
          displayName: user?.providerData?.[0]?.displayName,
          phoneNumber: user?.providerData?.[0]?.phoneNumber,
          photoURL: user?.providerData?.[0]?.photoURL,
          providerId: user?.providerData?.[0]?.providerId,
          uid: user?.providerData?.[0]?.uid,
        };

        axiosInstance
          .post("/login", data)
          .then((response) => {
            setLoading(false);
            if (response.data.data.length !== 0) {
              const {
                token,
                user: {
                  id,
                  full_name,
                  uuid,
                  avatar: { thumb },
                },
              } = response.data.data;
              setCurrentUser({
                token: token,
                uuid: uuid,
                fullName: full_name,
                avatar: thumb,
                id: id,
              });
              window.location.reload();
              history.push("/account");
            } else {
              history.push({
                pathname: "/register",
                state: data,
              });

              setError("email", { message: response?.data?.message });
            }
          })
          .catch((err) => {
            setLoading(false);
            setError("email", { message: err?.response?.data?.message });
          });
      })
      .catch((error) => {
        console.log(error.message);
        setError("email", { message: error.message });
      });
  };

  const signInWithFacebook = () => {
    auth
      .signInWithPopup(faceBookProvider)
      .then((res) => {
        var user = res.user;
        setLoading(true);
        let data = {
          email: res?.additionalUserInfo?.profile?.email,
          social_type: "facebook",
          device_type: "web",
          displayName: user?.providerData?.[0]?.displayName,
          phoneNumber: user?.providerData?.[0]?.phoneNumber,
          photoURL: user?.providerData?.[0]?.photoURL,
          providerId: user?.providerData?.[0]?.providerId,
          uid: user?.providerData?.[0]?.uid,
        };
        console.log("data", data);
        axiosInstance
          .post("/login", data)
          .then((response) => {
            setLoading(false);
            if (response.data.data.length !== 0) {
              const {
                token,
                user: {
                  full_name,
                  uuid,
                  id,
                  avatar: { thumb },
                },
              } = response.data.data;
              setCurrentUser({
                token: token,
                uuid: uuid,
                id: id,
                fullName: full_name,
                avatar: thumb,
              });
              window.location.reload();
              history.push("/account");
            } else {
              history.push({
                pathname: "/register",
                state: data,
              });

              setError("email", { message: response?.data?.message });
            }
          })
          .catch((err) => {
            setLoading(false);
            setError("email", { message: err?.response?.data?.message });
          });
      })
      .catch((error) => {
        setError("email", { message: error.message });
      });
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={3}>
        <div className={classes.paper}>
          <img alt="logo" src={logo} />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputField
                  errors={!!errors?.email}
                  fieldName="email"
                  type="text"
                  autoComplete="email"
                  label="Email Address"
                  control={control}
                  valueGot=""
                  helperText={errors?.email && errors?.email?.message}
                  rules={{
                    required: "Please enter email address",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputField
                  errors={!!errors?.password}
                  fieldName="password"
                  type="password"
                  autoComplete="password"
                  label="Password"
                  control={control}
                  valueGot=""
                  helperText={errors?.password && errors?.password?.message}
                  rules={{
                    required: "Please enter password address",
                  }}
                />
              </Grid>
            </Grid>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              loading={loading}
              text="Sign In"
            />
            <Grid container>
              <Grid item xs>
                <Link to="/account/forgot-password" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link to="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={1}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ textTransform: "none", width: "100%" }}
                    startIcon={<Search />}
                    onClick={signInWithGoogle}
                  >
                    Continue with Gmail
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ textTransform: "none", width: "100%" }}
                    onClick={signInWithFacebook}
                    startIcon={<FacebookIcon style={{ color: "#4267B2" }} />}
                  >
                    Continue with Facebook
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default Login;

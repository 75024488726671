import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import clsx from "clsx";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import { Link } from "react-router-dom";
import axiosInstance from "../../../helpers/axios";
import { setCurrentUser } from "../../../helpers/Utils";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ScheduleIcon from "@material-ui/icons/Schedule";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
const drawerWidth = 230;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(5) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function SideBar(props) {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [currentProviderType, setCurrentProviderType] = useState();
  const [open, setOpen] = useState();
  useEffect(() => {
    setOpen(props.sideBarOpen);
  }, [props.sideBarOpen]);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleLogout = async () => {
    await axiosInstance
      .post("/logout")
      .then((response) => {
        setCurrentUser();
        window.location.reload();
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Unauthenticated") {
          setCurrentUser();
          window.location.reload();
        }
      });
  };

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    await axiosInstance
      .post("/profile")
      .then((response) => {
        setCurrentProviderType(
          response.data.data?.user?.provider?.provider_type
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            <ListItem
              button
              component={Link}
              to="/account"
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0)}
            >
              <ListItemIcon>
                <DashboardOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/account/time-slots"
              selected={selectedIndex === 111}
              onClick={(event) => handleListItemClick(event, 111)}
            >
              <ListItemIcon>
                <ScheduleIcon />
              </ListItemIcon>
              <ListItemText primary="TimeSlots" />
            </ListItem>

            {currentProviderType == "practice" && (
              <ListItem
                button
                component={Link}
                to="/account/staff"
                selected={selectedIndex === 11}
                onClick={(event) => handleListItemClick(event, 11)}
              >
                <ListItemIcon>
                  <SupervisorAccountIcon />
                </ListItemIcon>
                <ListItemText primary="Staff" />
              </ListItem>
            )}

            <ListItem
              button
              component={Link}
              to="/account/visit-history"
              selected={selectedIndex === 2}
              onClick={(event) => handleListItemClick(event, 2)}
            >
              <ListItemIcon>
                <DashboardOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Visit History" />
            </ListItem>

            <ListItem
              button
              component={Link}
              to="/account/total-earnings"
              selected={selectedIndex === 3}
              onClick={(event) => handleListItemClick(event, 3)}
            >
              <ListItemIcon>
                <LocalAtmIcon />
              </ListItemIcon>
              <ListItemText primary="Total Earning" />
            </ListItem>

            {/* <ListItem button  component={Link} to="/total-earning" selected={selectedIndex === 4} onClick={(event) => handleListItemClick(event, 4)}>
                <ListItemIcon>
                  <DashboardOutlinedIcon/>
                  </ListItemIcon>
                <ListItemText primary='Request Pending Approval' />
              </ListItem> */}

            <ListItem
              button
              component={Link}
              to="/account/profile"
              selected={selectedIndex === 5}
              onClick={(event) => handleListItemClick(event, 5)}
            >
              <ListItemIcon>
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItem>
          </List>
          <Divider />
          <ListItem button onClick={(event) => handleLogout(event, 1)}>
            <ListItemIcon>
              <ExitToAppIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        {props.children}
      </main>
    </div>
  );
}

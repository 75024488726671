import React, { useEffect, useState } from "react";
import { Box, Grid, Modal, Typography } from "@material-ui/core";
import Layout from "../layouts/layout";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { useForm, useFieldArray } from "react-hook-form";
import Chip from "@material-ui/core/Chip";

import {
  AutoCompleteField,
  InputField,
  LoadingButton,
  TimeField,
} from "../../../components/forms/FormFields";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 20;
  const left = 10;

  return {
    top: `${top}%`,
    left: `${left}%`,
    width: "80%",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const TimeSlots = ({ match }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    setError,
    watch,
    reset,
    register,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: "slots", // unique name for your Field Array
    }
  );

  function getTimeStops(start, end, interval) {
    var startTime = moment(start, "HH:mm");
    var endTime = moment(end, "HH:mm");

    if (endTime.isBefore(startTime)) {
      endTime.add(1, "day");
    }

    var timeStops = [];

    while (startTime <= endTime) {
      timeStops.push(new moment(startTime).format("HH:mm"));
      startTime.add(interval, "minutes");
    }
    return timeStops;
  }

  const [open, setOpen] = useState(false);
  const [singleSlot, setSingleSlot] = useState({});

  const Slot = ({ open, setOpen, data }) => {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    console.log("data", data);
    const slots = getTimeStops(
      data.start_time,
      data.end_time,
      data?.interval?.id
    );
    let temp = [];
    for (let index = 0; index < slots.length; index++) {
      if (slots[index + 1]) {
        const start = slots[index];
        const end = slots[index + 1];

        temp.push({
          start: moment(start, "hh:mm").format("LT"),
          end: moment(end, "hh:mm").format("LT"),
        });
      }
    }
    console.log("temp", temp);
    return (
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 id="modal-title">{data?.day?.name} Slots</h2>
          <p id="modal-description" className={classes.root}>
            {temp.map((item) => {
              return <Chip label={`${item.start} - ${item.end}`} />;
            })}
          </p>
        </div>
      </Modal>
    );
  };

  return (
    <Layout>
      <Slot open={open} setOpen={setOpen} data={singleSlot} />
      <Typography variant="h3">TimeSlots</Typography>
      <Box mt={3}>
        <form onSubmit={handleSubmit((data) => console.log(data))}>
          {fields.map((item, index) => (
            <Grid container spacing={3} key={item.id}>
              <Grid item lg={2} sm={2} xl={2} xs={2}>
                <AutoCompleteField
                  errors={!!errors?.slots?.[index]?.day}
                  fieldName={`slots.${index}.day`}
                  type="text"
                  autoComplete="state_id"
                  label="Day"
                  control={control}
                  helperText={
                    errors?.slots?.[index]?.day &&
                    errors?.slots?.[index]?.day?.message
                  }
                  setValue={setValue}
                  options={[
                    {
                      id: "monday",
                      name: "Monday",
                    },
                    {
                      id: "tuesday",
                      name: "Tuesday",
                    },
                    {
                      id: "wednesday",
                      name: "Wednesday",
                    },
                    {
                      id: "thursday",
                      name: "Thursday",
                    },
                    {
                      id: "friday",
                      name: "Friday",
                    },
                    {
                      id: "saturday",
                      name: "Saturday",
                    },
                    {
                      id: "sunday",
                      name: "Sunday",
                    },
                  ]}
                  optionKey="id"
                  optionValue="name"
                  returnObject={true}
                  rules={{
                    required: "Please select Day",
                  }}
                  isLoading={true}
                />
              </Grid>
              <Grid item lg={3} sm={3} xl={3} xs={3}>
                <TimeField
                  errors={!!errors?.slots?.[index]?.start_time}
                  fieldName={`slots.${index}.start_time`}
                  autoComplete="start_time"
                  label="Start Time"
                  helperText={
                    errors?.slots?.[index]?.start_time &&
                    errors?.slots?.[index]?.start_time?.message
                  }
                  setValue={setValue}
                  control={control}
                  rules={{
                    required: "Please select enter time",
                  }}
                />
              </Grid>
              <Grid item lg={3} sm={3} xl={3} xs={3}>
                <TimeField
                  errors={!!errors?.slots?.[index]?.end_time}
                  fieldName={`slots.${index}.end_time`}
                  autoComplete="end_time"
                  label="End Time"
                  setValue={setValue}
                  helperText={
                    errors?.slots?.[index]?.end_time &&
                    errors?.slots?.[index]?.end_time?.message
                  }
                  control={control}
                  rules={{
                    required: "Please select end time",
                  }}
                />
              </Grid>
              <Grid item lg={2} sm={2} xl={2} xs={2}>
                <AutoCompleteField
                  errors={!!errors?.slots?.[index]?.interval}
                  fieldName={`slots.${index}.interval`}
                  type="text"
                  autoComplete="interval"
                  label="Interval"
                  control={control}
                  helperText={
                    errors?.slots?.[index]?.interval &&
                    errors?.slots?.[index]?.interval?.message
                  }
                  setValue={setValue}
                  valueGot={{
                    id: "15",
                    name: "15",
                  }}
                  options={[
                    {
                      id: "15",
                      name: "15",
                    },
                    {
                      id: "30",
                      name: "30",
                    },
                  ]}
                  optionKey="id"
                  optionValue="name"
                  returnObject={true}
                  rules={{
                    required: "Please select interval",
                  }}
                  isLoading={true}
                />
              </Grid>
              <Grid item lg={2} sm={2} xl={2} xs={2}>
                <LoadingButton
                  onClick={() => remove(index)}
                  type="submit"
                  variant="outlined"
                  color="secondary"
                  loading={false}
                  text="Delete"
                />
                <LoadingButton
                  onClick={(e) => {
                    setOpen(true);
                    setSingleSlot(getValues("slots." + index));
                  }}
                  type="submit"
                  variant="outlined"
                  color="primary"
                  loading={false}
                  text="View Slots"
                />
              </Grid>
            </Grid>
          ))}
          <Grid container spacing={3}>
            <Grid item lg={2} sm={2} xl={2} xs={2}>
              <LoadingButton
                onClick={() =>
                  append({
                    day: "",
                    start_time: "",
                    end_time: "",
                    interval: "",
                  })
                }
                type="button"
                variant="outlined"
                color="primary"
                loading={false}
                text="Append"
              />
            </Grid>
            <Grid item lg={2} sm={2} xl={2} xs={2}>
              {fields.length > 0 && (
                <LoadingButton
                  type="submit"
                  variant="outlined"
                  color="primary"
                  loading={false}
                  text="Submit"
                />
              )}
            </Grid>
          </Grid>
        </form>
      </Box>
    </Layout>
  );
};
export default TimeSlots;

import React from 'react';
import {Line} from 'react-chartjs-2';
import { Card, CardContent, CardHeader } from '@material-ui/core';

export default function InComeChart(props) {

  const state = canvas => {

    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0,0,100,0);

    return {
      labels: props?.data?.month_label,
      datasets: [
      {
        label: 'Income',
        fill: true,
        lineTension: 0.5,
        backgroundColor: gradient,
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: props?.data?.data_set,
        pointBackgroundColor: "#fff",
        pointBorderColor: "#3191bf",
        pointHoverBackgroundColor: "#3191bf",
        pointHoverBorderColor: "#fff",
        animations: {
          radius: {
            duration: 400,
            easing: 'linear',
            loop: (context) => context.active
          }
        },
      }
      ]
    }
  }

  return (
    <Card>
        <CardHeader title="Year 2022 Income" style={{paddingBottom:0}} />
          <CardContent>
          <Line
            {...props}
              data={state}
              options={{
                title:{
                  display:false,
                  text:'Average Rainfall per month',
                  fontSize:20
                },
                legend:{
                  display:false,
                  position:'right'
                }
              }}
            />
        </CardContent>
    </Card>
  );
}

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import axiosInstance from "../../helpers/axios";
import { Rating } from "@material-ui/lab";
import MuiAlert from "@material-ui/lab/Alert";
import { database } from "../../services/firebase";
import { getCurrentUser } from "../../helpers/Utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  center: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  avatar: {
    width: 120,
    height: 120,
  },
  miniCard: {
    background: "#f6fafd",
    color: theme?.palette?.primary?.main,
    fontSize: 18,
    textAlign: "center",
    boxShadow: "0 0 1px 1px #3191bf33",
  },
  CardContent: {
    padding: 15,
  },
  reviewBody: {
    width: 200,
    display: "flex",
    alignItems: "center",
  },
  w100: {
    width: "100%",
  },
}));

export default function ProfileCard() {
  const classes = useStyles();
  const [profile, setProfile] = useState({});
  const location = useLocation();
  const [accountStatus, setAccountStatus] = useState("");

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async (data) => {
    await axiosInstance
      .post("/profile", data)
      .then((response) => {
        setProfile(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    getStatus();
  }, []);

  const getStatus = () => {
    const dbRef = database.ref("provider");
    dbRef
      .child("status")
      .child(getCurrentUser().id)
      .on("value", (querySnapShot) => {
        let data = querySnapShot.val() ? querySnapShot.val() : {};
        setAccountStatus(data.status);
      });
  };
  return (
    <Card className={classes.card}>
      <CardContent className={classes.center}>
        <Container className={classes.center}>
          <Avatar
            alt="Remy Sharp"
            className={classes.avatar}
            src={profile?.user?.avatar?.thumb}
          />
        </Container>
        <Typography variant="h5" style={{ marginTop: 10 }}>
          {profile?.user?.full_name}
        </Typography>
        {profile?.user?.email}
        {profile?.user?.provider?.specialities?.name
          ? profile?.user?.provider?.specialities?.name
          : ""}

        {!!profile?.user?.provider?.average_ratings && (
          <Box className={classes.reviewBody} mt={1}>
            <Rating
              name="read-only"
              value={profile?.user?.provider?.average_ratings}
              readOnly
              precision={0.5}
            />
            <Box ml={2}>
              {parseFloat(profile?.user?.provider?.average_ratings).toFixed(2)}
            </Box>
          </Box>
        )}
        <Typography variant="h6" style={{ marginTop: 10 }}>
          Your Referral Code:
        </Typography>
        <span>{profile?.user?.refer_code}</span>

        <Grid item lg={12} sm={12} xl={12} xs={12} style={{ width: "100%" }}>
          <Box mt={2} style={{ width: "100%" }}>
            <Grid container spacing={3} mt={2}>
              <Grid item lg={6} sm={6} xl={6} xs={12}>
                <Card
                  className={`${classes.card} ${classes.miniCard} age-av-card `}
                >
                  <CardContent className={classes.CardContent}>
                    Age
                    <br />
                    {profile?.user?.age}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={6} sm={6} xl={6} xs={12}>
                <Card
                  className={`${classes.card} ${classes.miniCard} age-av-card `}
                >
                  <CardContent className={classes.CardContent}>
                    Status
                    <br />
                    {profile?.user?.availability === null
                      ? "offline"
                      : profile?.user?.availability}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {location.pathname !== "/account/profile" && (
          <Box mt={2} style={{ width: "100%" }}>
            <Container className={classes.center}>
              <Link to="/account/profile" style={{ textDecoration: "none" }}>
                <Button variant="contained" color="primary">
                  Go To Profile
                </Button>
              </Link>
            </Container>
          </Box>
        )}
        <Box mt={2} className={classes.w100}>
          {profile?.user?.braintree_status === "pending" && (
            <>
              <Alert className={classes.w100} severity="info">
                Braintree Pending
              </Alert>
              <br />
            </>
          )}
          {accountStatus === "approved" && (
            <>
              <Alert className={classes.w100} severity="success">
                Approved
              </Alert>
              <br />
            </>
          )}
          {accountStatus === "disapproved" && (
            <>
              <Alert className={classes.w100} severity="error">
                Disapproved by admin
              </Alert>
              <br />
            </>
          )}
          {profile?.user?.braintree_status === "active" && (
            <>
              <Alert className={classes.w100} severity="success">
                Bank Active
              </Alert>
              <br />
            </>
          )}
          {profile?.user?.braintree_status === "suspended" && (
            <>
              <Alert className={classes.w100} severity="error">
                Bank Suspended
              </Alert>
              <br />
            </>
          )}
        </Box>

        {/* 
                <Box mt={2} style={{width:'100%'}}>
                    <List component="nav" aria-label="main mailbox folders">
                
                        <ListItem button>
                            <ListItemText primary="Email" />
                            <ListItemSecondaryAction>
                            {profile?.email} 
                            </ListItemSecondaryAction>
                        </ListItem>
                       
                        <ListItem button>
                            <ListItemText primary="Phone No" />
                            <ListItemSecondaryAction>
                            +1 {profile?.phone_no}
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem button>
                            <ListItemText primary="Address" />
                            <ListItemSecondaryAction style={{textAlign: 'end',width: '70%'}}>
                              {profile?.address}
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem button>
                            <ListItemText primary="State" />
                            <ListItemSecondaryAction>
                            {profile?.state?.name}
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem button>
                            <ListItemText primary="City" />
                            <ListItemSecondaryAction>
                            {profile?.city?.name}
                            </ListItemSecondaryAction>
                        </ListItem>
                      
                    </List>
                </Box>
                  */}
      </CardContent>
    </Card>
  );
}

import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import {
  CircularProgress,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import MuiPhoneNumber from "material-ui-phone-number";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import { Chip } from "@material-ui/core";
import { KeyboardTimePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
  marginTopZero: {
    marginTop: theme.spacing(0),
  },
}));

export const InputField = (props) => {
  const [load, setLoad] = useState(true);
  const {
    fieldName,
    label,
    control,
    iProps,
    rules,
    initValue,
    helperText,
    errors,
    type,
    autoComplete,
    isLoading,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldName, props.valueGot);
    if (
      props.valueGot ||
      props.valueGot === "" ||
      props.valueGot === null ||
      isLoading
    )
      setLoad(false);
  }, [props.valueGot]);

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={initValue || initValue === 0 ? initValue : ""}
        rules={rules}
        render={({ field }) => (
          <>
            {load ? (
              <Skeleton height={60} />
            ) : (
              <TextField
                {...field}
                error={errors}
                variant="outlined"
                fullWidth
                size={"small"}
                label={label}
                type={type}
                id={fieldName}
                autoComplete={"no"}
                helperText={helperText}
                {...iProps}
              />
            )}
          </>
        )}
      />
    </>
  );
};

export const PhoneNoField = (props) => {
  const classes = useStyles();

  const {
    fieldName,
    label,
    control,
    iProps,
    rules,
    initValue,
    helperText,
    errors,
    type,
    autoComplete,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldName, props.valueGot);
  }, [props.valueGot]);

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={initValue || initValue === 0 ? initValue : ""}
        rules={rules}
        render={({ field }) => (
          <MuiPhoneNumber
            inputClass={classes.marginTopZero}
            name="phone"
            label="Phone Number"
            data-cy="user-phone"
            defaultCountry={"us"}
            onlyCountries={["us"]}
            preferredCountries={["us"]}
            regions="america"
            disableDropdown={true}
            countryCodeEditable={false}
            {...field}
            size={"small"}
            error={errors}
            variant="outlined"
            margin="normal"
            fullWidth
            label={label}
            type={type}
            id={fieldName}
            autoComplete={"no"}
            helperText={helperText}
            {...iProps}
          />
        )}
      />
    </>
  );
};

export const DateField = (props) => {
  const {
    fieldName,
    label,
    control,
    iProps,
    rules,
    initValue,
    helperText,
    errors,
    autoComplete,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldName, props.valueGot);
  }, [props.valueGot]);

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={initValue || initValue === 0 ? initValue : ""}
        rules={rules}
        render={({ field }) => (
          <DatePicker
            {...field}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            label={label}
            size={"small"}
            inputVariant="outlined"
            {...iProps}
            helperText={helperText}
            autoComplete={"no"}
            fullWidth
            error={errors}
          />
        )}
      />
    </>
  );
};
export const TimeField = (props) => {
  const {
    fieldName,
    label,
    control,
    iProps,
    rules,
    initValue,
    helperText,
    errors,
    autoComplete,
    setValue,
  } = props;
  const [selectedDate, setSelectedDate] = React.useState();
  useEffect(() => {
    props.valueGot && props.setValue(fieldName, props.valueGot);
  }, [props.valueGot]);
  const handleDateChange = (date) => {
    setSelectedDate(date);
    props.setValue(fieldName, date);
  };
  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={initValue || initValue === 0 ? initValue : ""}
        rules={rules}
        render={({ field }) => (
          <KeyboardTimePicker
            {...field}
            onChange={handleDateChange}
            value={selectedDate}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            label={label}
            size={"small"}
            inputVariant="outlined"
            {...iProps}
            helperText={helperText}
            autoComplete={"no"}
            fullWidth
            error={errors}
          />
        )}
      />
    </>
  );
};
export const SelectField = (props) => {
  const classes = useStyles();
  const [load, setLoad] = useState(true);
  const [menuItems, setMenuItem] = useState([]);
  const {
    fieldName,
    label,
    control,
    iProps,
    rules,
    initValue,
    helperText,
    errors,
    type,
    autoComplete,
    placeHolder,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldName, props.valueGot);
  }, [props.valueGot]);

  useEffect(() => {
    setMenuItem(props?.selectOptions);
    if (props?.selectOptions.length > 0) setLoad(false);
  }, [props?.selectOptions]);

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={initValue || initValue === 0 ? initValue : ""}
        rules={rules}
        render={({ field }) => (
          <>
            {load ? (
              <Skeleton height={58} />
            ) : (
              <FormControl
                error={errors}
                variant="outlined"
                className={classes.formControl}
                size="small"
              >
                <InputLabel id={label}>{label}</InputLabel>
                <Select
                  {...field}
                  variant="outlined"
                  fullWidth
                  label={label}
                  type={type}
                  id={fieldName}
                  size={"small"}
                  autoComplete={"no"}
                  {...iProps}
                >
                  <MenuItem value="" disabled>
                    {placeHolder}
                  </MenuItem>
                  {(menuItems || []).map((item, index) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
              </FormControl>
            )}
          </>
        )}
      />
    </>
  );
};

export const LoadingButton = (props) => {
  const { onClick, loading, text } = props;
  return (
    <Button {...props} onClick={onClick} disabled={loading}>
      {loading && <CircularProgress size={23} />}
      {!loading && text}
    </Button>
  );
};

export const AutoCompleteField = (props) => {
  const [load, setLoad] = useState(true);
  const [value, setValue] = React.useState({});
  const [inputValue, setInputValue] = React.useState("");

  const {
    fieldName,
    control,
    iProps,
    rules,
    initValue,
    helperText,
    errors,
    type,
    autoComplete,
    options,
    isLoading,
    optionKey,
    optionValue,
    label,
    returnObject,
  } = props;

  useEffect(() => {
    if (isLoading) {
      setLoad(false);
      if (returnObject) {
        props.valueGot && props.setValue(fieldName, props?.valueGot);
      } else {
        props.valueGot &&
          props.setValue(fieldName, props?.valueGot?.[optionKey]);
      }
    }

    if (isLoading == false) {
      setLoad(true);
    }
    if (props.valueGot == undefined) {
      setValue({});
    } else {
      setValue(props.valueGot);
      if (props.valueGot?.[optionKey] !== undefined) {
        setInputValue(props.valueGot?.[optionKey]);
      }
    }
  }, [props.valueGot, isLoading]);

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={initValue || initValue === 0 ? initValue : ""}
        rules={rules}
        render={(field) => (
          <>
            {load ? (
              <Skeleton height={65} style={{ transformOrigin: "0 3%" }} />
            ) : (
              <Autocomplete
                className="form-item"
                options={options}
                {...iProps}
                autoComplete={false}
                filterSelectedOptions
                getOptionSelected={(option, value) => {
                  if (value?.[optionKey] !== undefined) {
                    return option?.[optionKey] === value?.[optionKey];
                  } else {
                    return "";
                  }
                }}
                getOptionLabel={(option) => {
                  return option?.[optionValue] !== undefined
                    ? option?.[optionValue]
                    : "";
                }}
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                  iProps?.onChange(newValue);
                  if (returnObject) {
                    props.setValue(fieldName, newValue);
                  } else {
                    props.setValue(fieldName, newValue?.[optionKey]);
                  }
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  console.log("newInputValue", newInputValue);
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => {
                  let _data = {
                    ...params,
                    inputProps: {
                      ...params.inputProps,
                      autoComplete: "no",
                    },
                  };
                  return (
                    <TextField
                      {..._data}
                      error={errors}
                      variant="outlined"
                      label={label}
                      size="small"
                      autoComplete={"no"}
                      helperText={helperText}
                    />
                  );
                }}
              />
            )}
          </>
        )}
      />
    </>
  );
};

export const AutoCompleteMultipleField = (props) => {
  const [load, setLoad] = useState(true);
  const [value, setValue] = useState([]);
  const {
    fieldName,
    control,
    iProps,
    rules,
    initValue,
    helperText,
    errors,
    type,
    autoComplete,
    options,
    isLoading,
    optionKey,
    optionValue,
    label,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldName, props.valueGot);
    if (isLoading) {
      setLoad(false);
      props.setValue(fieldName, props?.valueGot);
    }
    if (props.valueGot == undefined) {
      setValue([]);
    } else {
      setValue(props.valueGot);
    }
  }, [props.valueGot, isLoading]);

  return (
    <>
      <Controller
        name={fieldName}
        control={control}
        defaultValue={initValue || initValue === 0 ? initValue : ""}
        rules={rules}
        render={(field) => (
          <>
            {load ? (
              <Skeleton height={65} />
            ) : (
              <Autocomplete
                size="small"
                multiple
                value={value}
                // {...iProps}
                autoComplete={false}
                onChange={(event, newValue) => {
                  setValue(newValue);
                  iProps?.onChange(newValue);
                  props.setValue(fieldName, newValue);
                }}
                options={options}
                getOptionLabel={(option) => {
                  return option?.[optionValue];
                }}
                getOptionSelected={(option, value) => {
                  return option?.[optionKey] === value?.[optionKey];
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => {
                    return (
                      <Chip
                        size="small"
                        label={option?.[optionValue]}
                        {...getTagProps({ index })}
                      />
                    );
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors}
                    variant="outlined"
                    label={label}
                    size="small"
                    autoComplete={"no"}
                    helperText={helperText}
                  />
                )}
              />
            )}
          </>
        )}
      />
    </>
  );
};

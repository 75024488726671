import {
    Card,
    CardContent,
    Typography,
    Box 
  } from '@material-ui/core';
  import Countdown from 'react-countdown';


  const Appointment = (props)=>{


  const renderer = ({ hours, minutes, seconds, completed }) => {
  
      return <Typography variant="h4" >{hours}:{minutes}:{seconds}</Typography>;
   
  };

    return (<Card {...props}>
      <CardContent>
      <Typography variant="h5" color="textSecondary">Upcoming Appointment</Typography>
      {
        props?.data?.diffInSeconds !== null ?
        <Countdown date={Date.now()+props?.data?.diffInSeconds*1000}    renderer={renderer}/>
        :
        <Box mt={3}>
          <Typography variant="h5" ><div>No Upcoming Appointment</div></Typography>
        </Box>
      }
        
      </CardContent>
    </Card>)
}
  
  export default Appointment;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function RecentPatient(props) {
  const classes = useStyles();

  return (
    <Card>
        <CardHeader title="Last Patients" style={{paddingBottom:0}} />
      <CardContent>
        <List dense className={classes.root}>
          {

           props.lastPatients.length > 0 ? props.lastPatients.map((value) => {
            const labelId = `checkbox-list-secondary-label-${value.id}`;
            return (
              <ListItem key={value.id} button>
                <ListItemAvatar>
                  <Avatar
                    alt={value.patient_name}
                    src={value.patient?.avatar?.thumb}
                  />
                </ListItemAvatar>
                <ListItemText id={labelId} className="reg-date" 
                primary={value.patient_name}
                secondary={`${moment(value?.created_at).format('ll')} ${moment(value?.provider_time).format('h:mm A')}`}
                />
              </ListItem>
            );
          }):
            <ListItem  button>
            <ListItemText 
            primary='No paitents'
            />
        </ListItem>
          }
        </List>
        </CardContent>
    </Card>
  );
}

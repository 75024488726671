import {
    Avatar,
    Card,
    CardContent,
    Grid,
    Typography
  } from '@material-ui/core';
  import makeStyles from "@material-ui/core/styles/makeStyles";
  import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

  const useStyles = makeStyles(theme => ({
    icon: {
        backgroundColor: theme?.palette?.primary?.main,
        height: 56, 
        width: 56
    },
  }));

  const Income = (props)=>{
    const classes = useStyles();

    return (<Card {...props}>
      <CardContent>
        <Grid container spacing={3} justify='space-between' >
          <Grid item >
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              Total Earning
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
                {props?.data?.totalIncome}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.icon} >
              <AttachMoneyIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>)
}
  
  export default Income;
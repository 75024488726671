import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../../assets/images/logos/logo.png";
import { useForm } from "react-hook-form";
import {
  InputField,
  LoadingButton,
} from "../../../components/forms/FormFields";
import { useHistory, useLocation, useParams } from "react-router";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import axios from "axios";
import { store } from "react-notifications-component";
import axiosInstance from "../../../helpers/axios";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  loginWithFb: {
    backgroundColor: "rgb(255, 255, 255)",
    display: "inline-flex",
    alignItems: "center",
    color: "rgba(0, 0, 0, 0.54)",
    boxShadow:
      "rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px",
    borderRadius: "2px",
    border: "1px solid transparent",
    fontSize: "14px",
    fontFamily: "Roboto, sans-serif",
    padding: "10px 10px 10px 0px",
    fontWeight: "500",
  },
}));

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ResetPassword(props) {
  const [loading, setLoading] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendSuccessMessage, setSendSuccessMessage] = useState("");
  let query = useQuery();
  let { token } = useParams();
  let history = useHistory();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const classes = useStyles();

  const onSubmit = async (data) => {
    data.email = query.get("email");
    data.token = token;
    setLoading(true);
    await axiosInstance
      .post("/password/reset", data)
      .then((response) => {
        setLoading(false);
        setSendSuccess(true);
        setSendSuccessMessage(response?.data?.message);
        store.addNotification({
          title: "Success",
          message: "Password Change Successfully. Now you can login your account.",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });

       // window.location.href = '/login'
       // history.push("/login");
      })
      .catch(function (err) {
        // handle error
        setLoading(false);
        let _data = err.response?.data?.data?.errors;

        if (_data !== undefined) {
          for (const key in _data) {
            if (Object.hasOwnProperty.call(_data, key)) {
              const element = _data[key];
              if (element?.[0]) {
                setError(key, {
                  type: "manual",
                  message: element?.[0],
                });
                store.addNotification({
                  title: "Error",
                  message: element?.[0],
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 5000,
                    onScreen: true,
                  },
                });
              }
            }
          }
        } else {
          store.addNotification({
            title: "Error",
            message: err.response?.data?.message
              ? err.response?.data?.message
              : err.response?.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          if (err.response?.data?.data?.error_code == "passwords.token") {
            history.push("/login");
          }
        }
      });
  };
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={11} gutterBottom>
        <div className={classes.paper}>
          <img alt="logo" src={logo} />
          {!sendSuccess ? (
            <>
              <Typography component="h1" variant="h5">
                Reset Password
              </Typography>
              <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                  <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <InputField
                      errors={!!errors?.email}
                      fieldName="email"
                      type="text"
                      autoComplete="email"
                      iProps={{
                        disabled: true,
                      }}
                      label="Email Address"
                      control={control}
                      setValue={setValue}
                      valueGot={query.get("email") ? query.get("email") : ""}
                      helperText={errors?.email && errors?.email?.message}
                      rules={{
                        required: "Please enter email address",
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <InputField
                      errors={!!errors?.password}
                      fieldName="password"
                      type="password"
                      autoComplete="off"
                      label="Enter Password"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={errors?.password && errors?.password?.message}
                      rules={{
                        required: "Please enter password",
                        minLength: {
                          value: 8,
                          message: "Password must have at least 8 characters",
                        },
                        // validate: (value) =>
                        //   value === getValues("password_confirmation") ||
                        //   "The passwords do not match",
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <InputField
                      errors={!!errors?.password_confirmation}
                      fieldName="password_confirmation"
                      type="password"
                      autoComplete="off"
                      label="Re Enter your Password"
                      control={control}
                      valueGot={""}
                      setValue={setValue}
                      helperText={
                        errors?.password_confirmation &&
                        errors?.password_confirmation?.message
                      }
                      rules={{
                        required: "Please enter confirmation password",
                        minLength: {
                          value: 8,
                          message: "Password must have at least 8 characters",
                        },
                        validate: (value) =>
                          value === getValues("password") ||
                          "The passwords do not match",
                      }}
                    />
                  </Grid>
                </Grid>

                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  loading={loading}
                  text="Send Request"
                />
              </form>
            </>
          ) : (
            <div>
              <Typography align="center" gutterBottom>
                <CheckCircleOutlineIcon
                  style={{ color: green[500], fontSize: 60 }}
                />
              </Typography>
              <Typography
                align="center"
                variant="h5"
                component="h5"
                gutterBottom
              >
                Password Reset Email Send
              </Typography>
              <Typography align="center" variant="p" component="p" gutterBottom>
                {sendSuccessMessage}
              </Typography>
              <Box align="center">
                <Button variant="contained" href="/login" color="primary">
                  Done
                </Button>
              </Box>
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
}

export default ResetPassword;

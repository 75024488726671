import { Box, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import UserHeader from '../../../components/user/UserHeader';
import Layout from '../layouts/layout';
import TotalPaitent from '../../../components/dashboard/totalPatient';
import Income from '../../../components/dashboard/income';
import Appointment from '../../../components/dashboard/Appointment';
import RecentPaitents from '../../../components/dashboard/RecentPaitents';
import UpComingAppointmentCard from '../../../components/dashboard/UpComingAppointmentCard';
import InComeChart from '../../../components/dashboard/InComeChart';
import axiosInstance from '../../../helpers/axios';
import { Skeleton } from '@material-ui/lab';


function Dashboard(props) {

  const [dashboardData,setDashboardData] = useState([]);
  useEffect(()=>{
    getDashboard()
  },[])

  const  getDashboard = async ()=>{
    await axiosInstance.get('/provider/dashboard')
    .then(response => {
      setDashboardData(response.data.data)
    })
    .catch(err =>{
        console.log(err)
    });
  };

  return (
          <Layout>
            <UserHeader/>
              <Grid item lg={12} sm={12} xl={12} xs={12}>
                <Box mt={2}>
                    <Grid container spacing={3} mt={2}>
                      <Grid item lg={dashboardData?.diffInSeconds?4:6} sm={6} xl={dashboardData?.diffInSeconds?4:6} xs={12}>
                        {dashboardData?.totalPatients || dashboardData?.totalPatients === 0? (
                            <TotalPaitent data={dashboardData}/>
                          ) : (
                            <Skeleton variant="rect" width={210} height={118} />
                          )}
                      </Grid>
                      <Grid item  lg={dashboardData?.diffInSeconds?4:6}  sm={6} xl={dashboardData?.diffInSeconds?4:6} xs={12}>
                        {dashboardData?.totalPatients || dashboardData?.totalPatients === 0 ? (
                        <Income data={dashboardData}/>
                        ) : (
                            <Skeleton variant="rect" width={210} height={118} />
                          )}
                      </Grid>
                      {
                        dashboardData?.diffInSeconds &&
                        <Grid item  lg={4}  sm={6} xl={4} xs={12}>
                        {dashboardData?.diffInSeconds || dashboardData?.diffInSeconds == null ? (
                            <Appointment data={dashboardData}/>
                          ) : (
                              <Skeleton variant="rect" width={210} height={118} />
                            )}
                        </Grid>
                      }
                    </Grid>
                  </Box>
                  <Box mt={2}> 
                    <Grid container spacing={3} mt={2}>
                      <Grid item  lg={6}  sm={12} xl={4} xs={12}>
                      {dashboardData || dashboardData?.upcomming ? (
                      <UpComingAppointmentCard upcomming={dashboardData?.upcomming}/>
                      ) : (
                            <Skeleton variant="rect" width={210} height={118} />
                          )}
                      </Grid>
                      <Grid item  lg={6}  sm={12} xl={3} xs={12}>
                      {dashboardData?.lastPatients ? (
                        <RecentPaitents lastPatients={dashboardData?.lastPatients}/>
                        ) : (
                            <Skeleton variant="rect" width={210} height={118} />
                          )}
                      </Grid>
                      <Grid item  lg={12}  sm={12} xl={5} xs={12}>
                      {dashboardData?.inComeChart ? (
                        <InComeChart data={dashboardData?.inComeChart}/>
                        ) : (
                            <Skeleton variant="rect" width={210} height={118} />
                          )}
                      </Grid>
                    </Grid>
                  </Box>
              </Grid>
          </Layout>
    );
}

export default Dashboard;

import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../helpers/axios";
import Layout from "../layouts/layout";
import AgGridCustom from "../../../components/AgGridCustom/AgGridCustom";
import moment from "moment";
import { useForm } from "react-hook-form";
import {
  InputField,
  LoadingButton,
} from "../../../components/forms/FormFields";
import { store } from "react-notifications-component";

const StaffList = ({ match, data, index }) => {
  const [rowData, setRowData] = useState();
  const [loader, setLoader] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    setError,
    watch,
    reset,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    getStaffList();
  }, []);

  const getStaffList = async () => {
    await axiosInstance
      .get("/staff-manager")
      .then((response) => {
        setRowData(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columnDefs = [
    {
      headerName: "Full Name",
      field: "full_name",
    },
    {
      headerName: "Email",
      field: "email",
    },
    {
      headerName: "Created At",
      field: "created_at",
      cellRendererFramework: (params) => {
        return moment(params.value).format("llll");
      },
    },
  ];

  const onSubmit = async (data) => {
    setLoader(true);
    data.type = "staff";
    data.device_token = "web";
    data.device_type = "web";
    data.social_type = "web";

    await axiosInstance
      .post("/staff-manager", data)
      .then((response) => {
        getStaffList();
        store.addNotification({
          title: "Success",
          message: "Staff Create Successfully.",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        setLoader(false);
        reset();
      })
      .catch((err) => {
        setLoader(false);
        let _data = err.response?.data?.data?.errors;
        if (_data !== undefined) {
          for (const key in _data) {
            if (Object.hasOwnProperty.call(_data, key)) {
              const element = _data[key];
              if (element?.[0]) {
                setError(key, {
                  type: "manual",
                  message: element?.[0],
                });
                store.addNotification({
                  title: "Error",
                  message: element?.[0],
                  type: "danger",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                    duration: 5000,
                    onScreen: true,
                  },
                });
              }
            }
          }
        }
        // store.addNotification({
        //   title: "Error",
        //   message: err.response?.data?.message
        //     ? err.response?.data?.message
        //     : err.response?.message,
        //   type: "danger",
        //   insert: "top",
        //   container: "top-right",
        //   animationIn: ["animate__animated", "animate__fadeIn"],
        //   animationOut: ["animate__animated", "animate__fadeOut"],
        //   dismiss: {
        //     duration: 5000,
        //     onScreen: true,
        //   },
        // });
      });
  };

  return (
    <Layout>
      <Typography variant="h3">Staff</Typography>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item lg={5} sm={12} md={5} xl={5} xs={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                  <InputField
                    errors={!!errors?.first_name}
                    fieldName="first_name"
                    type="text"
                    autoComplete="off"
                    label="First Name"
                    control={control}
                    valueGot={""}
                    setValue={setValue}
                    helperText={
                      errors?.first_name && errors?.first_name?.message
                    }
                    rules={{
                      required: "Please enter first name",
                    }}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                  <InputField
                    errors={!!errors?.last_name}
                    fieldName="last_name"
                    type="text"
                    autoComplete="off"
                    label="Last Name"
                    control={control}
                    valueGot={""}
                    setValue={setValue}
                    helperText={errors?.last_name && errors?.last_name?.message}
                    rules={{
                      required: "Please enter last name",
                    }}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                  <InputField
                    errors={!!errors?.email}
                    fieldName="email"
                    type="text"
                    autoComplete="off"
                    label="Email"
                    control={control}
                    valueGot={""}
                    setValue={setValue}
                    helperText={errors?.email && errors?.email?.message}
                    rules={{
                      required: "Please enter email",
                    }}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                  <InputField
                    errors={!!errors?.password}
                    fieldName="password"
                    type="password"
                    autoComplete="off"
                    label="Enter Password"
                    control={control}
                    valueGot={""}
                    setValue={setValue}
                    helperText={errors?.password && errors?.password?.message}
                    rules={{
                      required: "Please enter password",
                      minLength: {
                        value: 8,
                        message: "Password must have at least 8 characters",
                      },
                      // validate: (value) =>
                      //   value === getValues("confirm_password") ||
                      //   "The passwords do not match",
                    }}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                  <InputField
                    errors={!!errors?.confirm_password}
                    fieldName="confirm_password"
                    type="password"
                    autoComplete="off"
                    label="Re Enter your Password"
                    control={control}
                    valueGot={""}
                    setValue={setValue}
                    helperText={
                      errors?.confirm_password &&
                      errors?.confirm_password?.message
                    }
                    rules={{
                      required: "Please enter confirmation password",
                      minLength: {
                        value: 8,
                        message: "Password must have at least 8 characters",
                      },
                      validate: (value) =>
                        value === getValues("password") ||
                        "The passwords do not match",
                    }}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={loader}
                    text="Register"
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item lg={7} sm={12} md={7} xl={7} xs={12}>
            <AgGridCustom columnDefs={columnDefs} rowData={rowData} />
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};
export default StaffList;

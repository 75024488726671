import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import Layout from "../layouts/layout";
import AppointmentCard from "./AppointmentCard";
import axiosInstance from "../../../helpers/axios";
import { Skeleton } from "@material-ui/lab";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`upcoming-appointment-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `upcoming-appointment-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
}));

const UpcomingAppointment = ({ match }) => {
  const [cardData, setCardData] = useState([]);
  const [requestReponse, setRequestReponse] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    getScheduledAppointment();
  }, []);

  const getScheduledAppointment = async (data) => {
    await axiosInstance
      .post("/provider/bookings/list", data)
      .then((response) => {
        setCardData(response.data.data);
        setRequestReponse(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout>
      <Typography variant="h3" gutterBottom>
        Appointments
      </Typography>
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab
              label={`Scheduled (${
                cardData?.scheduled?.length === 0 ||
                cardData?.scheduled?.length === undefined
                  ? 0
                  : cardData?.scheduled?.length
              })`}
              {...a11yProps(0)}
            />
            <Tab
              label={`Completed (${
                cardData?.completed?.length === 0 ||
                cardData?.completed?.length === undefined
                  ? 0
                  : cardData?.completed?.length
              })`}
              {...a11yProps(1)}
            />
            <Tab
              label={`Cancel (${
                cardData?.cancel?.length === 0 ||
                cardData?.cancel?.length === undefined
                  ? 0
                  : cardData?.cancel?.length
              })`}
              {...a11yProps(2)}
            />
            <Tab
              label={`Declined (${
                cardData?.declined?.length === 0 ||
                cardData?.declined?.length === undefined
                  ? 0
                  : cardData?.declined?.length
              })`}
              {...a11yProps(3)}
            />
            <Tab
              label={`Archived (${
                cardData?.completed?.filter(
                  (item) => item.provider_archive_at !== null
                ).length === 0 ||
                cardData?.completed?.filter(
                  (item) => item.provider_archive_at !== null
                ).length === undefined
                  ? 0
                  : cardData?.completed?.filter(
                      (item) => item.provider_archive_at !== null
                    ).length
              })`}
              {...a11yProps(4)}
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <Box mt={3}>
              <Grid container spacing={3}>
                {cardData?.scheduled?.length > 0 || requestReponse
                  ? cardData?.scheduled?.map((item, index) => (
                      <AppointmentCard data={item} index={index} />
                    ))
                  : [1, 2, 3, 4, 6, 7, 8, 5, 4, 8].map((item, index) => (
                      <Grid item lg={4} sm={12} xl={3} xs={12}>
                        <Skeleton variant="rect" width={"100%"} height={250} />
                      </Grid>
                    ))}
                {(cardData?.scheduled?.length === 0 ||
                  cardData?.scheduled?.length === undefined) && (
                  <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <Typography
                      style={{ textAlign: "center" }}
                      variant="h6"
                      gutterBottom
                    >
                      No Appointment{" "}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Box mt={3}>
              <Grid container spacing={3}>
                {cardData?.completed?.length > 0 || requestReponse
                  ? cardData?.completed?.map((item, index) => (
                      <AppointmentCard data={item} index={index} />
                    ))
                  : [1, 2, 3, 4, 6, 7, 8, 5, 4, 8].map((item, index) => (
                      <Grid item lg={4} sm={12} xl={3} xs={12}>
                        <Skeleton variant="rect" width={"100%"} height={250} />
                      </Grid>
                    ))}

                {(cardData?.completed?.length === 0 ||
                  cardData?.completed?.length === undefined) && (
                  <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <Typography
                      style={{ textAlign: "center" }}
                      variant="h6"
                      gutterBottom
                    >
                      No Appointment{" "}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <Box mt={3}>
              <Grid container spacing={3}>
                {cardData?.cancel?.length > 0 || requestReponse
                  ? cardData?.cancel?.map((item, index) => (
                      <AppointmentCard data={item} index={index} />
                    ))
                  : [1, 2, 3, 4, 6, 7, 8, 5, 4, 8].map((item, index) => (
                      <Grid item lg={4} sm={12} xl={3} xs={12}>
                        <Skeleton variant="rect" width={"100%"} height={250} />
                      </Grid>
                    ))}

                {(cardData?.cancel?.length === 0 ||
                  cardData?.cancel?.length === undefined) && (
                  <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <Typography
                      style={{ textAlign: "center" }}
                      variant="h6"
                      gutterBottom
                    >
                      No Appointment{" "}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={3} dir={theme.direction}>
            <Box mt={3}>
              <Grid container spacing={3}>
                {cardData?.declined?.length > 0 || requestReponse
                  ? cardData?.declined?.map((item, index) => (
                      <AppointmentCard data={item} index={index} />
                    ))
                  : [1, 2, 3, 4, 6, 7, 8, 5, 4, 8].map((item, index) => (
                      <Grid item lg={4} sm={12} xl={3} xs={12}>
                        <Skeleton variant="rect" width={"100%"} height={250} />
                      </Grid>
                    ))}

                {cardData?.declined?.length === 0 ||
                  (cardData?.declined?.length === undefined && (
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                      <Typography
                        style={{ textAlign: "center" }}
                        variant="h6"
                        gutterBottom
                      >
                        No Appointment{" "}
                      </Typography>
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={4} dir={theme.direction}>
            <Box mt={3}>
              <Grid container spacing={3}>
                {cardData?.completed?.filter(
                  (item) => item.provider_archive_at !== null
                )?.length > 0 || requestReponse
                  ? cardData?.completed
                      ?.filter((item) => item.provider_archive_at !== null)
                      ?.map((item, index) => (
                        <AppointmentCard data={item} index={index} />
                      ))
                  : [1, 2, 3, 4, 6, 7, 8, 5, 4, 8].map((item, index) => (
                      <Grid item lg={4} sm={12} xl={3} xs={12}>
                        <Skeleton variant="rect" width={"100%"} height={250} />
                      </Grid>
                    ))}

                {(cardData?.completed?.filter(
                  (item) => item.provider_archive_at !== null
                )?.length === 0 ||
                  cardData?.completed?.filter(
                    (item) => item.provider_archive_at !== null
                  )?.length === undefined) && (
                  <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <Typography
                      style={{ textAlign: "center" }}
                      variant="h6"
                      gutterBottom
                    >
                      No Appointment
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </TabPanel>
        </SwipeableViews>
      </div>
    </Layout>
  );
};
export default UpcomingAppointment;

import React, { useEffect, useState } from "react";
import { Avatar, Box, CardHeader, Grid } from "@material-ui/core";
import Layout from "../../layouts/layout";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  DateField,
  InputField,
  LoadingButton,
  SelectField,
  PhoneNoField,
  AutoCompleteField,
  AutoCompleteMultipleField,
} from "../../../../components/forms/FormFields";
import { useForm } from "react-hook-form";
import axiosInstance from "../../../../helpers/axios";
import { store } from "react-notifications-component";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  marginTopZero: {
    marginTop: theme.spacing(0),
  },
}));

function Profile(props) {
  const classes = useStyles();
  const [profile, setProfile] = useState({});
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState({});
  const [specialities, setSpecialities] = useState([]);
  const [loader, setLoader] = useState(false);
  const [profileLoad, setProfileLoad] = useState(false);

  const onSubmit = async (data) => {
    data.city_id = data.city_id.id;
    data.state_id = data.state_id.id;
    data.speciality_id = 1;
    if (data.speciality_ids.id !== undefined) {
      data.speciality_ids = data.speciality_ids.id.toString();
    } else {
      data.speciality_ids = data.speciality_ids.map(({ id }) => id).toString();
    }
    setLoader(true);
    await axiosInstance
      .post("/profile/update", data)
      .then((response) => {
        store.addNotification({
          title: "Success",
          message: "Profile Update Successfully.",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        store.addNotification({
          title: "Error",
          message: err.response?.data?.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      });
  };

  useEffect(() => {
    getProfile();
    getStates();
    getSpecialities();
  }, []);

  useEffect(() => {
    if (profile?.user?.state_id) getCities();
    if (profile?.user?.state_id) {
      setProfileLoad(true);
    }
  }, [profile?.user?.state_id]);

  const getProfile = async (data) => {
    await axiosInstance
      .post("/profile", data)
      .then((response) => {
        setProfile(response.data.data);
        setCity(response.data.data.user.city);
        if (response.data.data.user.state_id === -1) setValue("state_id", "");
        if (response?.data?.data?.user?.provider?.speciality_id === -1)
          setValue("speciality_id", "");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStates = async (data) => {
    await axiosInstance
      .get("/list/states", data)
      .then((response) => {
        setStates(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSpecialities = async (data) => {
    await axiosInstance
      .get("/specialities", data)
      .then((response) => {
        setSpecialities(response.data.data);
      })
      .catch((err) => {
        store.addNotification({
          title: "Error",
          message: err?.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      });
  };

  const getCities = async (state_id = null) => {
    setCities([]);
    await axiosInstance
      .get("/list/cities", {
        params: {
          state_id: state_id ? state_id : profile?.user?.state_id,
        },
      })
      .then((response) => {
        setCities(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  const handleChangeState = (value) => {
    setValue("state_id", value?.id);
    setValue("city_id", []);
    setCity({});
    if (value) {
      getCities(value?.id);
    }
  };

  const handleChangeCity = (value) => {
    setValue("city_id", value?.id);
  };

  return (
    <Layout>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardHeader
            avatar={
              <Avatar
                aria-label={profile?.user?.first_name}
                alt={profile?.user?.first_name}
                src={profile?.user?.avatar?.thumb}
              />
            }
            title={profile?.user?.full_name}
            subheader={`Registeration: ${moment
              .utc(profile?.user?.created_at)
              .format("LLLL")}`}
          />
          <CardContent>
            <Box mt={2}>
              <Grid container spacing={3}>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                  <InputField
                    errors={!!errors?.first_name}
                    fieldName="first_name"
                    type="text"
                    autoComplete="first_name"
                    label="First Name"
                    control={control}
                    valueGot={profile?.user?.first_name}
                    setValue={setValue}
                    helperText={
                      errors?.first_name && errors?.first_name?.message
                    }
                    rules={{
                      required: "Please enter first name",
                    }}
                  />
                </Grid>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                  <InputField
                    errors={!!errors?.last_name}
                    fieldName="last_name"
                    type="text"
                    autoComplete="last_name"
                    label="Last Name"
                    control={control}
                    valueGot={profile?.user?.last_name}
                    setValue={setValue}
                    helperText={errors?.last_name && errors?.last_name?.message}
                    rules={{
                      required: "Please enter last name",
                    }}
                  />
                </Grid>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                  <InputField
                    errors={!!errors?.email}
                    fieldName="email"
                    type="text"
                    autoComplete="email"
                    label="Email"
                    control={control}
                    iProps={{ disabled: true }}
                    valueGot={profile?.user?.email}
                    setValue={setValue}
                    helperText={errors?.email && errors?.email?.message}
                    // rules={{
                    //   required: "Please enter email",
                    // }}
                  />
                </Grid>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                  <PhoneNoField
                    errors={!!errors?.phone_no}
                    fieldName="phone_no"
                    autoComplete="phone_no"
                    label="Phone No"
                    control={control}
                    valueGot={profile?.user?.phone_no}
                    setValue={setValue}
                    helperText={errors?.phone_no && errors?.phone_no?.message}
                    rules={{
                      required: "Please enter phone no",
                    }}
                  />
                  <Box mt={1}>
                    <DateField
                      errors={!!errors?.dob}
                      fieldName="dob"
                      autoComplete="dob"
                      label="Date of Birth"
                      iProps={{
                        format: "MM/dd/yyyy",
                        maxDate: moment().subtract(16, "years"),
                      }}
                      valueGot={profile?.user?.dob}
                      setValue={setValue}
                      control={control}
                      helperText={errors?.dob && errors?.dob?.message}
                      rules={{
                        required: "Please enter date of birth",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={8} sm={12} xl={8} xs={12}>
                  <InputField
                    errors={!!errors?.address}
                    fieldName="address"
                    type="text"
                    autoComplete="address"
                    label="Address"
                    valueGot={profile?.user?.address}
                    setValue={setValue}
                    iProps={{
                      multiline: true,
                      rows: 5,
                      className: classes.marginTopZero,
                    }}
                    control={control}
                    helperText={
                      errors?.first_name && errors?.first_name?.message
                    }
                    rules={{
                      required: "Please enter first name",
                    }}
                  />
                </Grid>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                  {states && (
                    <AutoCompleteField
                      errors={!!errors?.state_id}
                      fieldName="state_id"
                      type="text"
                      autoComplete="state_id"
                      label="State"
                      control={control}
                      helperText={errors?.state_id && errors?.state_id?.message}
                      valueGot={profile?.user?.state}
                      setValue={setValue}
                      options={states}
                      optionKey="id"
                      optionValue="name"
                      returnObject={true}
                      iProps={{
                        onChange: handleChangeState,
                      }}
                      rules={{
                        required: "Please select state",
                      }}
                      isLoading={states.length > 0 ? true : false}
                    />
                  )}
                </Grid>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                  <AutoCompleteField
                    errors={!!errors?.city_id}
                    fieldName="city_id"
                    autoComplete="city_id"
                    label="City"
                    control={control}
                    helperText={errors?.city_id && errors?.city_id?.message}
                    iProps={{
                      onChange: handleChangeCity,
                    }}
                    rules={{
                      required: "Please select city",
                    }}
                    optionKey="id"
                    optionValue="name"
                    returnObject={true}
                    valueGot={city}
                    setValue={setValue}
                    placeHolder="Select City"
                    options={cities}
                    isLoading={cities.length > 0 ? true : false}
                  />
                </Grid>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                  <InputField
                    errors={!!errors?.zip_code}
                    fieldName="zip_code"
                    type="text"
                    autoComplete="zip_code"
                    label="Zip Code"
                    valueGot={profile?.user?.zip_code}
                    setValue={setValue}
                    iProps={{
                      className: classes.marginTopZero,
                    }}
                    control={control}
                    helperText={errors?.zip_code && errors?.zip_code?.message}
                    rules={{
                      required: "Please enter zip code",
                    }}
                  />
                </Grid>
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                  <InputField
                    errors={!!errors?.provider_type}
                    fieldName="provider_type"
                    type="text"
                    autoComplete="off"
                    label="Provider Type"
                    control={control}
                    iProps={{ disabled: true }}
                    valueGot={profile?.user?.provider?.provider_type}
                    setValue={setValue}
                    helperText={
                      errors?.provider_type && errors?.provider_type?.message
                    }
                  />
                </Grid>

                <Grid item lg={4} sm={6} xl={4} xs={12}>
                  {profile?.user?.provider?.provider_type == "individual" && (
                    <AutoCompleteField
                      errors={!!errors?.speciality_ids}
                      fieldName="speciality_ids"
                      type="text"
                      autoComplete="speciality_ids"
                      label="Speciality"
                      control={control}
                      helperText={
                        errors?.speciality_ids &&
                        errors?.speciality_ids?.message
                      }
                      optionKey="id"
                      optionValue="name"
                      returnObject={true}
                      onChange={handleChangeState}
                      valueGot={profile?.user?.provider?.specialities[0]}
                      isLoading={specialities?.length > 0 ? true : false}
                      setValue={setValue}
                      options={specialities}
                      rules={{
                        required: "Select speciality",
                      }}
                    />
                  )}
                  {profile?.user?.provider?.provider_type == "practice" && (
                    <AutoCompleteMultipleField
                      errors={!!errors?.speciality_ids}
                      fieldName="speciality_ids"
                      autoComplete="off"
                      label="* Select Specialities"
                      control={control}
                      setValue={setValue}
                      options={specialities}
                      optionKey="id"
                      optionValue="name"
                      isLoading={specialities?.length > 0 ? true : false}
                      helperText={
                        errors?.speciality_ids &&
                        errors?.speciality_ids?.message
                      }
                      valueGot={profile?.user?.provider?.specialities}
                    />
                  )}
                </Grid>
                {profile?.user?.provider?.provider_type !== "individual" && (
                  <Grid item lg={4} sm={6} xl={4} xs={12}>
                    <InputField
                      errors={!!errors?.business_name}
                      fieldName="business_name"
                      type="text"
                      autoComplete="business_name"
                      label="Business Name"
                      valueGot={profile?.user?.provider?.business_name}
                      setValue={setValue}
                      iProps={{
                        className: classes.marginTopZero,
                      }}
                      control={control}
                      helperText={
                        errors?.business_name && errors?.business_name?.message
                      }
                      // rules={{
                      //   required: "Please enter business name",
                      // }}
                    />
                  </Grid>
                )}
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                  <InputField
                    errors={!!errors?.bank_name}
                    fieldName="bank_name"
                    type="text"
                    autoComplete="bank_name"
                    label="Bank Name"
                    valueGot={profile?.user?.provider?.bank_name}
                    setValue={setValue}
                    iProps={{
                      className: classes.marginTopZero,
                    }}
                    control={control}
                    helperText={errors?.bank_name && errors?.bank_name?.message}
                    rules={{
                      required: "Please enter bank name",
                    }}
                  />
                </Grid>
                {profile?.user?.provider?.provider_type == "practice" && 
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                  <InputField
                    errors={!!errors?.tax_id}
                    fieldName="tax_id"
                    type="text"
                    autoComplete="tax_id"
                    label="Tax Id"
                    valueGot={profile?.user?.provider?.tax_id}
                    setValue={setValue}
                    iProps={{
                      className: classes.marginTopZero,
                    }}
                    control={control}
                    helperText={errors?.tax_id && errors?.tax_id?.message}
                    rules={{
                      required: "Please enter tax id",
                    }}
                  />
                </Grid>
                  }
                <Grid item lg={4} sm={6} xl={4} xs={12}>
                  <InputField
                    errors={!!errors?.account_number}
                    fieldName="account_number"
                    type="text"
                    autoComplete="account_number"
                    label="Account Number"
                    valueGot={profile?.user?.provider?.account_number}
                    setValue={setValue}
                    iProps={{
                      className: classes.marginTopZero,
                    }}
                    control={control}
                    helperText={
                      errors?.account_number && errors?.account_number?.message
                    }
                    rules={{
                      required: "Please enter account number",
                      // minLength: {
                      //   value: 10,
                      //   message: "Please enter valid account number",
                      // },
                      // maxLength: {
                      //   value: 13,
                      //   message: "Please enter valid account number",
                      // },
                      // pattern: {
                      //   value: /[0-9]{10}/,
                      //   message: "Please enter valid account number.", // JS only: <p>error message</p> TS only support string
                      // },
                    }}
                  />
                </Grid>

                <Grid item lg={4} sm={6} xl={4} xs={12}>
                  <InputField
                    errors={!!errors?.routing_number}
                    fieldName="routing_number"
                    type="text"
                    autoComplete="routing_number"
                    label="Routing Number"
                    valueGot={profile?.user?.provider?.routing_number}
                    setValue={setValue}
                    iProps={{
                      className: classes.marginTopZero,
                    }}
                    control={control}
                    helperText={
                      errors?.routing_number && errors?.routing_number?.message
                    }
                    rules={{
                      required: "Please enter routing number",
                      // minLength: {
                      //   value: 9,
                      //   message: "Please enter valid routing number",
                      // },
                      // maxLength: {
                      //   value: 13,
                      //   message: "Please enter valid routing number",
                      // },
                      // pattern: {
                      //   value: /[0-9]{9}/,
                      //   message: "Please enter valid routing number.", // JS only: <p>error message</p> TS only support string
                      // },
                    }}
                  />
                </Grid>
                {profile?.user?.provider?.provider_type == "individual" && (
                  <Grid item lg={4} sm={6} xl={4} xs={12}>
                   <InputField
                            errors={!!errors?.ssn}
                            fieldName="ssn"
                            type="text"
                            autoComplete="off"
                            valueGot={profile?.user?.ssn}
                            label="SSN"
                            setValue={setValue}
                            iProps={{
                              className: classes.marginTopZero,
                            }}
                            control={control}
                            helperText={errors?.ssn && errors?.ssn?.message}
                            rules={{
                              required: "Please enter ssn",
                            }}
                          />
                  </Grid>
                )}
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    loading={loader}
                    text="Update"
                  />
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </form>
    </Layout>
  );
}

export default Profile;

import axios from "axios";

const adminBaseUrl = "https://api.sosdoctorhousecall.com/api/";

axios.interceptors.response.use(
  function (response) {
    let override = JSON.parse(response);
    override.data = override.data.data;
    return JSON.parse(override);
  },
  function (error) {
    let override = JSON.parse(error);
    override.data = override.data.data;
    return Promise.reject(JSON.parse(override));
  }
);

const axiosInstance = axios.create({
  baseURL: adminBaseUrl,
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${
      JSON.parse(localStorage.getItem("sos_provider_user"))?.token
    }`,
    "X-Custom-Header": "provider",
  },
  transformResponse: [
    function (data) {
      let override = JSON.parse(data);
      console.log("override",override)
      override.data = override.data.data;
      if (override?.message == "Unauthenticated") {
        localStorage.removeItem("sos_provider_user");
        window.location.href = "/";
      }
      return override;
    },
  ],
});

export default axiosInstance;

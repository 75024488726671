import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import logo from "../../../assets/images/logos/logo.png";
import { useForm } from "react-hook-form";
import { InputField,LoadingButton } from '../../../components/forms/FormFields';
import axiosInstance from '../../../helpers/axios';
import { useHistory } from 'react-router';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    loginWithFb:{
      backgroundColor: 'rgb(255, 255, 255)',
      display: 'inline-flex',
      alignItems: 'center',
      color: 'rgba(0, 0, 0, 0.54)',
      boxShadow: 'rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px',
      borderRadius: '2px',
      border: '1px solid transparent',
      fontSize: '14px',
      fontFamily: 'Roboto, sans-serif',
      padding: '10px 10px 10px 0px',
      fontWeight: '500',
    }
}));

function ForgotPassword(props) {
    const [loading, setLoading] = useState(false);
    const [sendSuccess, setSendSuccess] = useState(false);
    const [sendSuccessMessage, setSendSuccessMessage] = useState('');

    const history = useHistory();
    const { control, handleSubmit, formState: { errors },setError } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
          email: '',
          password: '',
        },
      });

      const classes = useStyles();

      const  onSubmit = async (data)=>{

        console.log(data)
        setLoading(true)
          await axiosInstance.post('/forgot-password',data)
          .then(response => {
            setLoading(false)
            setSendSuccess(true)
            setSendSuccessMessage(response?.data?.message)
           // window.location.reload();
           // history.push('/account')
          })
          .catch(err =>{
              setLoading(false)
              setError("email",{'message':err?.response?.data?.message});
          });
      };
  return (
   
    <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh'}}
    >
      <Grid item xs={3} gutterBottom>
      <div className={classes.paper}>
        <img alt="logo" src={logo} />
        { !sendSuccess ?
        <>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <form className={classes.form}  onSubmit={handleSubmit(onSubmit)}>
          <InputField
            errors={!!errors?.email}
            fieldName="email"
            type="text"
            autoComplete='email'
            label="Email Address"
            control={control}
            valueGot=''
            helperText={errors?.email && errors?.email?.message}
            rules={{
                required: "Please enter email address",
            }}
          />
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            loading={loading}
            text="Send Request"
          />
        </form>
        </>
        :
        <div>
          <Typography align="center"  gutterBottom >
            <CheckCircleOutlineIcon style={{ color: green[500] , fontSize: 60 }}/>
          </Typography>
          <Typography align="center" variant="h5" component="h5" gutterBottom>
              Password Reset Email Send
          </Typography>
          <Typography   align="center" variant="p" component="p" gutterBottom>
              {sendSuccessMessage}
          </Typography>
         <Box align="center" >
          <Button variant="contained" href="/login" color="primary">
                  Done
            </Button>
         </Box>
        </div>
        }
      </div>
      </Grid>
    </Grid>
  );
}

export default (ForgotPassword);

export const getCurrentUser = () => {
  let user = JSON.parse(localStorage.getItem('sos_provider_user')) || null;
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('sos_provider_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('sos_provider_user');
    }
  } catch (error) {
  }
};

export const isAuthenticated = !!getCurrentUser()

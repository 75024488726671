import React, { Suspense, useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";

import PrivateRoute from "./helpers/PrivateRoute";
import PublicRoute from "./helpers/PublicRoute";
import theme from "./assets/theme";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import Profile from "./views/pages/dashboard/user/profile";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import UpcomingAppointment from "./views/pages/Appointment/UpcomingAppointment";
import VisitHistory from "./views/pages/VisitHistory/VisitHistory";
import Earning from "./views/pages/Earnings/Earning";
import ForgotPassword from "./views/pages/auth/forgotPassword";
import Login from "./views/pages/auth/login";
import AuthRegister from "./views/pages/auth/register";
import Dashboard from "./views/pages/dashboard/dashboard";
import StaffList from "./views/pages/Staff";
import axiosInstance from "./helpers/axios";
import ResetPassword from "./views/pages/auth/resetPassword";
import TimeSlots from "./views/pages/TimeSlots/TimeSlots";

function App() {
  const [currentProviderType, setCurrentProviderType] = useState();

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("sos_provider_user"))?.token) {
      getProfile();
    }
  }, []);
  const getProfile = async () => {
    await axiosInstance
      .post("/profile")
      .then((response) => {
        setCurrentProviderType(
          response.data.data?.user?.provider?.provider_type
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <ReactNotification />
      <CssBaseline />
      <Suspense fallback={<div className="loading" />}>
        <Router>
          <Switch>
            <Redirect to="/login" from="/" exact />
            <PrivateRoute
              component={(props) => <Dashboard {...props} />}
              path="/account"
              exact
            />
            {currentProviderType == "practice" && (
              <PrivateRoute
                component={(props) => <StaffList {...props} />}
                path="/account/staff"
                exact
              />
            )}
            <PrivateRoute
              component={(props) => <Profile {...props} />}
              path="/account/profile"
              exact
            />
            <PrivateRoute
              component={(props) => <UpcomingAppointment {...props} />}
              path="/account/upcoming-appointment"
              exact
            />
            <PrivateRoute
              component={(props) => <VisitHistory {...props} />}
              path="/account/visit-history"
              exact
            />
            <PrivateRoute
              component={(props) => <TimeSlots {...props} />}
              path="/account/time-slots"
              exact
            />
            <PrivateRoute
              component={(props) => <Earning {...props} />}
              path="/account/total-earnings"
              exact
            />
            <PublicRoute
              restricted
              component={(props) => <Login {...props} />}
              path="/login"
              exact
            />
            <PublicRoute
              restricted
              component={(props) => <ForgotPassword {...props} />}
              path="/account/forgot-password"
              exact
            />
            <PublicRoute
              restricted
              component={(props) => <AuthRegister {...props} />}
              path="/register"
              exact
            />
            <PublicRoute
              restricted
              component={(props) => <ResetPassword {...props} />}
              path="/reset-password/:token"
              exact
            />
            <PublicRoute
              component={(props) => <h1> Error </h1>}
              path="/error"
              exact
            />

            <Redirect to="/error" />
          </Switch>{" "}
        </Router>{" "}
      </Suspense>{" "}
    </ThemeProvider>
  );
}

export default App;

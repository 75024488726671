import {
  Avatar,
  CardActions,
  Chip,
  Grid,
  Grow,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { List, Card, CardContent, CardHeader } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  center: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  avatar: {
    width: 50,
    height: 50,
    boxShadow: "0 0 7px 4px #bebfc0",
  },
  miniCard: {
    background: "#f6fafd",
    color: theme?.palette?.primary?.main,
    fontSize: 18,
    textAlign: "center",
    boxShadow: "0 0 1px 1px #3191bf33",
  },
  CardContent: {
    padding: 15,
  },
  chipBody: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const AppointmentCard = ({ match, data, index }) => {
  const classes = useStyles();

  const timeOut = (index === 0 ? 1 : index) * 1000;
  return (
    <Grid item lg={4} sm={12} xl={3} xs={12} className="position-relative">
      <Grow in={!!data}>
        <Card className={classes.card}>
          {data?.user_type == "staff" && (
            <div class="card-staff-body">
              <Chip
                color="primary"
                size="small"
                label={`Staff : ${data?.staff?.full_name}`}
              />
            </div>
          )}
          <CardHeader
            style={{ paddingBottom: 0 }}
            avatar={
              <Avatar
                aria-label="recipe"
                src={data?.patient?.avatar?.thumb}
                className={classes.avatar}
              />
            }
            title={data?.patient_name}
            subheader={`${data?.patient_age} Age`}
          />
          <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
            <List
              component="nav"
              aria-label="main mailbox folders"
              style={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <ListItem
                disableGutters={true}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <ListItemText
                  primary="Symptoms"
                  secondary={data?.symptoms?.name}
                />
              </ListItem>
              <ListItem
                disableGutters={true}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <ListItemText
                  primary="Email"
                  secondary={data?.patient?.email}
                />
              </ListItem>
              <ListItem
                disableGutters={true}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <ListItemText
                  primary="Phone No"
                  secondary={data?.patient?.phone_no}
                />
              </ListItem>
            </List>
          </CardContent>
          <CardActions>
            {data?.provider_time && (
              <Chip
                color="primary"
                size="small"
                label={`${moment(data?.provider_time).format("llll")}`}
              />
            )}
            {data?.provider_price && (
              <Chip
                color="primary"
                size="small"
                label={`${data?.provider_price}$`}
              />
            )}
          </CardActions>
        </Card>
      </Grow>
    </Grid>
  );
};
export default AppointmentCard;

// --- Imports --- //
import React from "react";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
// --- Fill Image Card Component Imports --- //
import {
  FiCard,
  FiCardActionArea,
  FiCardContent,
  FiCardMedia,
} from "./FullImageCard";
import { getCurrentUser } from "../../helpers/Utils";
import Grid from "@material-ui/core/Grid";
import iOSUrl from "../../assets/images/appstore.svg";
import playUrl from "../../assets/images/google.svg";
// --- Style --- //
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    maxWidth: "100%",
  },
  media: {
    height: 140,
  },
  fiCardContent: {
    color: "#ffffff",
    background: `linear-gradient( 95deg, ${theme?.palette?.primary?.main} 60%, #fafafa00 115%)`,
    padding: "50px",
  },
  fiCardContentTextSecondary: {
    color: "rgba(255,255,255,0.78)",
  },
}));

const UserHeader = () => {
  const classes = useStyles();
  const { fullName } = getCurrentUser();

  return (
    <Box>
      <FiCard className={classes.card}>
        <FiCardMedia
          media="picture"
          alt="Contemplative Reptile"
          image="https://www.medelita.com/media/general2020/sensitive-withearbuds.jpg"
          title="Contemplative Reptile"
        />
        <FiCardContent className={classes.fiCardContent}>
          <Typography gutterBottom variant="h5" component="h2">
            {fullName}
          </Typography>
          <Typography variant="h5" component="h4">
            If you want take booking go to app store and installed SOS Doctor
            House Call APP.
          </Typography>
          <Box mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{display: 'flex'}}>
                  <a href="https://apps.apple.com/pk/app/sos-doctor/id1563312196" target="_blank">
                    <img src={iOSUrl} />
                  </a>
                  <a  style={{paddingLeft: '5px',width:'141.2px'}} href="https://play.google.com/store/apps/details?id=com.soshousecall" target="_blank">
                    <img src={playUrl} />
                  </a>
                </div>
              </Grid>
            </Grid>
          </Box>
        </FiCardContent>
      </FiCard>
    </Box>
  );
};

export default UserHeader;

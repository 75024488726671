import React, { useState } from 'react';
import Header from './header';
import SideBar from './sideBar';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Box, Grid } from '@material-ui/core';
import ProfileCard from '../../../components/dashboard/ProfileCard';
const Layout = (props) => {
const [sideBarOpen,setSideBarOpen] = useState(true);

  const handleDrawerClose = () => {
      setSideBarOpen(sideBarOpen?false:true);
  };

    return (
          <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Header handleDrawerClose={handleDrawerClose} />
                <SideBar sideBarOpen={sideBarOpen}>
                <Box mt={2}>
                  <Grid container spacing={3} mt={2}>
                    <Grid item lg={9} sm={12} xl={9} xs={12}>
                      {props.children}
                    </Grid>
                    <Grid item lg={3} sm={12} xl={3} xs={12}>
                      <ProfileCard/>
                    </Grid>
                  </Grid>
                </Box>
                </SideBar>
            </MuiPickersUtilsProvider>
          </>
  );
}

export default Layout;

import { Box, Card, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import InComeChart from "../../../components/dashboard/InComeChart";
import {
  FiCard,
  FiCardActionArea,
  FiCardContent,
} from "../../../components/user/FullImageCard";
import axiosInstance from "../../../helpers/axios";
import Layout from "../layouts/layout";

// --- Style --- //
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    maxWidth: "100%",
  },
  media: {
    height: 140,
  },
  fiCardContent: {
    color: "#ffffff",
    background: theme?.palette?.primary?.main,
    padding: "20px",
  },
  fiCardContentTextSecondary: {
    color: "rgba(255,255,255,0.78)",
  },
  fiCardContentMini: {
    padding: "20px",
    color: "#3191bf",
    background: "#f6fafd",
    boxShadow: "0 0 1px 1px #3191bf33",
  },
}));

const Earning = ({ match }) => {
  const classes = useStyles();
  const [earnings, setEarnings] = useState();

  useEffect(() => {
    getEarnings();
  }, []);

  const getEarnings = async () => {
    await axiosInstance
      .get("/provider/total-earnings")
      .then((response) => {
        setEarnings(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Layout>
      <Typography variant="h3">Earnings</Typography>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={12} md={6} xl={3} xs={12}>
            <FiCard className={classes.card}>
              <FiCardActionArea>
                <FiCardContent className={classes.fiCardContent}>
                  <Typography gutterBottom variant="h6" component="h6">
                    Total Earning
                  </Typography>
                  <Typography gutterBottom variant="h4" component="h4">
                    ${earnings?.totalIncome}
                  </Typography>
                </FiCardContent>
              </FiCardActionArea>
            </FiCard>
          </Grid>

          <Grid item lg={3} sm={12} md={6} xl={3} xs={12}>
            <FiCard className={classes.card}>
              <FiCardActionArea>
                <FiCardContent className={classes.fiCardContentMini}>
                  <Typography gutterBottom variant="h6" component="h6">
                    Today Earning
                  </Typography>
                  <Typography gutterBottom variant="h4" component="h6">
                    ${earnings?.todayIncome}
                  </Typography>
                </FiCardContent>
              </FiCardActionArea>
            </FiCard>
          </Grid>
          <Grid item lg={3} sm={12} md={6} xl={3} xs={12}>
            <FiCard className={classes.card}>
              <FiCardActionArea>
                <FiCardContent className={classes.fiCardContentMini}>
                  <Typography gutterBottom variant="h6" component="h6">
                    Weekly Earning
                  </Typography>
                  <Typography gutterBottom variant="h4" component="h6">
                    ${earnings?.weeklyIncome}
                  </Typography>
                </FiCardContent>
              </FiCardActionArea>
            </FiCard>
          </Grid>
          <Grid item lg={3} sm={12} md={6} xl={3} xs={12}>
            <FiCard className={classes.card}>
              <FiCardActionArea>
                <FiCardContent className={classes.fiCardContentMini}>
                  <Typography gutterBottom variant="h6" component="h6">
                    Monthly Earning
                  </Typography>
                  <Typography gutterBottom variant="h4" component="h6">
                    ${earnings?.monthlyIncome}
                  </Typography>
                </FiCardContent>
              </FiCardActionArea>
            </FiCard>
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item lg={12} sm={12} md={12} xl={12} xs={12}>
            <Card>
              <InComeChart data={earnings?.inComeChart} height={100} />
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};
export default Earning;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Chip, Grid, Typography } from '@material-ui/core';
import { FiCard, FiCardActionArea, FiCardContent, FiCardMedia } from '../user/FullImageCard';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  card: {
    maxWidth: '100%',
  },
  media: {
    height: 140
  },
  fiCardContent: {
    color: "#ffffff",
    background: `radial-gradient(rgb(0 96 142 / 49%) 60%,rgb(0 96 142 / 49%) 60%)`,
    padding: '50px 20px'
  },
  fiCardContentTextSecondary: {
    color: "rgba(255,255,255,0.78)"
  }
}));

const whiteBackground = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAT4AAACfCAMAAABX0UX9AAAAA1BMVEX///+nxBvIAAAASElEQVR4nO3BMQEAAADCoPVPbQ0PoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABODcYhAAEl463hAAAAAElFTkSuQmCC';
export default function UpComingAppointmentCard(props) {
  const classes = useStyles();

  function capitalizeFirstLetter(str) {
    if(str)
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return (
    <FiCard className={classes.card}>
          <FiCardActionArea>
            <FiCardMedia
              media="picture"
              alt={props?.upcomming?.patient_name}
              image={props?.upcomming?.patient?.avatar?.medium?props?.upcomming?.patient?.avatar?.medium:whiteBackground}
              title={props?.upcomming?.patient_name}
            />
            <FiCardContent className={classes.fiCardContent+' upcoming-card'}>
                <Typography gutterBottom variant="h5" component="h2">
                    {props?.upcomming ?'Upcoming Appointment':"No Upcoming Appointment"}
                </Typography>
                <Typography gutterBottom variant="h5" component="h4">
                    {props?.upcomming?.patient_name}
                </Typography>
                <Typography gutterBottom variant="p" component="p">
                {moment(props?.upcomming?.provider_time).format('LLLL')}
                </Typography>
                <Typography
                    variant="body2"
                    className={classes.fiCardContentTextSecondary}
                    component="div"
                >
                <Box mt={2}>
                 {
                   props?.upcomming && 
                   <Grid container spacing={1} mt={2} className="chip-group">
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <Chip label={`Age : ${props?.upcomming?.patient_age?props?.upcomming?.patient_age:0}`}/>
                    </Grid>
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <Chip  label={`Time : ${moment(props?.upcomming?.provider_time).format('llll')}`}/>
                    </Grid>
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <Chip label={`Request Type : ${capitalizeFirstLetter(props?.upcomming?.request_type)?capitalizeFirstLetter(props?.upcomming?.request_type):''}`}/>
                    </Grid>
                   </Grid>
                 }
               
                </Box>
              </Typography>
            </FiCardContent>
          </FiCardActionArea>
        </FiCard>
  );
}

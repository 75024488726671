import {
  Avatar,
  CardActions,
  Chip,
  Grid,
  Grow,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Popover,
} from "@material-ui/core";
import React, { useEffect } from "react";
import {
  Box,
  Button,
  List,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { Rating } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  center: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  avatar: {
    width: 50,
    height: 50,
    boxShadow: "0 0 7px 4px #bebfc0",
  },
  miniCard: {
    background: "#f6fafd",
    color: theme?.palette?.primary?.main,
    fontSize: 18,
    textAlign: "center",
    boxShadow: "0 0 1px 1px #3191bf33",
  },
  CardContent: {
    padding: 15,
  },
  chipBody: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  reviewBody: {
    display: "flex",
    alignItems: "center",
  },
  review: {
    padding: theme.spacing(2),
  },
}));

const AppointmentCard = ({ match, data, index }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Grid item lg={4} sm={12} xl={3} xs={12} className="appointment-card-row position-relative">
      <Grow in={!!data}>
        <Card className={classes.card}>
          {data?.user_type == "staff" && (
            <div class="card-staff-body">
              <Chip
                color="primary"
                size="small"
                label={`Staff : ${data?.staff?.full_name}`}
              />
            </div>
          )}
          <CardHeader
            avatar={
              <Avatar
                aria-label="recipe"
                src={data?.patient?.avatar?.thumb}
                className={classes.avatar}
              />
            }
            title={data?.patient_name}
            subheader={`${data?.patient_age} Age`}
          />
          <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem
                disableGutters={true}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <ListItemText
                  primary="Symptoms"
                  secondary={data?.symptoms?.name}
                />
              </ListItem>
              <ListItem
                disableGutters={true}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <ListItemText
                  primary="Email"
                  secondary={data?.patient?.email}
                />
              </ListItem>
              <ListItem
                disableGutters={true}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <ListItemText
                  primary="Phone No"
                  secondary={data?.patient?.phone_no}
                />
              </ListItem>
              <ListItem disableGutters={true}>
                <ListItemText primary="Rating" />

                <ListItemSecondaryAction>
                  {data?.ratings?.rating && (
                    <Box className={classes.reviewBody}>
                      <Rating
                        name="read-only"
                        value={data?.ratings?.rating}
                        readOnly
                        precision={0.5}
                      />{" "}
                      <Box>{parseFloat(data?.ratings?.rating).toFixed(2)}</Box>
                    </Box>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem disableGutters={true}>
                <ListItemText primary="Review" />
                <ListItemSecondaryAction>
                  {data?.ratings?.review && (
                    <>
                      <Button
                        aria-describedby={id}
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleClick}
                      >
                        Review
                      </Button>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <Typography className={classes.review}>
                          {data?.ratings?.review}
                        </Typography>
                      </Popover>
                    </>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </CardContent>
          <CardActions>
            {data?.provider_time && (
              <Chip
                color="primary"
                size="small"
                label={`${moment(data?.provider_time).format("llll")}`}
              />
            )}
            {data?.provider_price && (
              <Chip
                color="primary"
                size="small"
                label={`${data?.provider_price}$`}
              />
            )}
          </CardActions>
        </Card>
      </Grow>
    </Grid>
  );
};
export default AppointmentCard;

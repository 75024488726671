import firebase from 'firebase/compat/app'
import "firebase/compat/auth";
import 'firebase/compat/database'
import 'firebase/compat/messaging';


const firebaseConfig = {
  apiKey: "AIzaSyCFxChYc7AfibLQhgx-AdGERvpBlRNcE_c",
  authDomain: "sosdhc.firebaseapp.com",
  databaseURL: "https://sosdhc-default-rtdb.firebaseio.com",
  projectId: "sosdhc",
  storageBucket: "sosdhc.appspot.com",
  messagingSenderId: "755308742407",
  appId: "1:755308742407:web:0356ac08f8b6a17409e7a2",
  measurementId: "G-SYD6KXPHGG",
};

firebase.initializeApp(firebaseConfig);

export const googleProvider = new firebase.auth.GoogleAuthProvider()
googleProvider.addScope('https://www.googleapis.com/auth/userinfo.email');
export const faceBookProvider = new firebase.auth.FacebookAuthProvider();
export const auth = firebase.auth();
export const database = firebase.database();
export const messaging = firebase.messaging();

// navigator.serviceWorker.register('../firebase-messaging-sw.js')
//   .then(function (registration) {
//     // Registration was successful
//     console.log('firebase-message-sw :ServiceWorker registration successful with scope: ', registration.scope);
//   }, function (err) {
//     // registration failed :(
//     console.log('firebase-message-sw: ServiceWorker registration failed: ', err);
//   });

// messaging.onMessage((payload) => {
//   console.log('Message received. ', payload);
//   // ...
// });